import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDEmailField from "../../UI/MDEmailField/MDEmailField";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { setAdminUserEmail, setAdminUserFirstName, setAdminUserLastName } from "../../_reducers_/FileCardSlice";
import ReactHtmlParser from "html-react-parser";

interface PropType {
  control: any;
  errors: any;
  setValue: any;
}

const AdminUser = (props: PropType): ReactElement<PropType> => {
  const dispatch = useDispatch();
  const { t } = useTranslation("general");
  const translationLabel = t("adminUser.switch");
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);

  const { control, errors, setValue } = props;

  const adminUser = fileCard.fileCard.adminUser;

  return (
    <div>
      <h2>{translationLabel}</h2>
      <p>{ReactHtmlParser(t("adminUser.description"))}</p>

      <MDTextField
        label={t("adminUser.firstName")}
        name="adminUserFirstName"
        value={adminUser?.firstName ?? ""}
        error={errors.adminUserFirstName}
        control={control}
        onChangeSetValue={(e: string): void => {
          dispatch(setAdminUserFirstName(e));
        }}
      />
      <MDTextField
        label={t("adminUser.lastName")}
        name="adminUserLastName"
        value={adminUser?.lastName ?? ""}
        error={errors.adminUserLastName}
        control={control}
        onChangeSetValue={(e: string): void => {
          dispatch(setAdminUserLastName(e));
        }}
      />

      <MDEmailField
        control={control}
        setValue={setValue}
        emailLabel={t("adminUser.email")}
        emailName="adminUserEmail"
        emailError={errors.adminUserEmail}
        emailValue={adminUser?.email ?? ""}
        emailOnChange={(e: string): void => {
          dispatch(setAdminUserEmail(e));
        }}
      />
    </div>
  );
};

export default AdminUser;
