import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isNullOrUndefined } from "../../utils/CommonUtils";
import MDTextField from "../MDTextField/MDTextField";

interface PropType {
  control: any;
  setValue: any;
  trigger: any;
  formState: any;
  vatNumberRequired: boolean;
  vatNumberLabel: string;
  vatNumberName: string;
  vatNumberError: any;
  vatNumberValue: any;
  vatNumberRegexPattern: any;
  vatNumberPrefix: any;
  vatNumberFormat: any;
  vatNumberOnChange: (value: any) => void;
}

const MDVatNumberField = (props: PropType): ReactElement<PropType> => {
  const [t] = useTranslation("general");

  const { formState, setValue, trigger, vatNumberName, vatNumberValue } = props;

  const validateVatNumber = (value: string | null) => {
    if (isNullOrUndefined(value) || isNullOrUndefined(props.vatNumberRegexPattern)) {
      return true;
    } else {
      const re = new RegExp(props.vatNumberRegexPattern ?? "");
      return re.test(String(value))
        ? true
        : t("validation.wrongVatNumberFormat", {
            format: props.vatNumberFormat,
          });
    }
  };

  useEffect(() => {
    setValue(vatNumberName, vatNumberValue);
  }, [vatNumberName, vatNumberValue, setValue]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(vatNumberName);
    }
  }, [vatNumberName, formState.isSubmitted, trigger]);

  return (
    <div>
      <MDTextField
        control={props.control}
        label={props.vatNumberLabel}
        name={props.vatNumberName}
        value={props.vatNumberValue}
        error={props.vatNumberError}
        prefixText={props.vatNumberPrefix}
        InputLabelProps={props.vatNumberPrefix ? { shrink: true } : undefined}
        onChangeSetValue={props.vatNumberOnChange}
        rules={{
          required: {
            message: t("validation.requiredField"),
            value: props.vatNumberRequired,
          },
          validate: validateVatNumber,
        }}
      />
    </div>
  );
};

export default MDVatNumberField;
