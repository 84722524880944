import { FormControlLabel, Switch } from "@mui/material";
import { ChangeEvent, ReactElement } from "react";

interface PropTypes {
  control: any;
  label: string;
  name: string;
  error: any;
  value: boolean | undefined;
  onChangeSetValue: (value: any) => void;
}

const MDSwitchButton = (props: PropTypes): ReactElement<PropTypes> => {
  const { name, value, label, onChangeSetValue } = props;

  return (
    <>
      <FormControlLabel
        style={{ marginBottom: "15px" }}
        control={
          <Switch
            checked={value}
            value={value}
            onChange={(
              event: ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => {
              onChangeSetValue(checked);
            }}
            name={name}
            color="primary"
          />
        }
        label={label}
      />
    </>
  );
};

export default MDSwitchButton;
