import React, { ReactElement } from "react";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";

const CustomerSaveConfirmation = (): ReactElement => {
  const { t } = useTranslation("general");
  return <div>{ReactHtmlParser(t("html.customerSaveConfirmation"))}</div>;
};

export default CustomerSaveConfirmation;
