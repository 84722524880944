import React, { ReactElement } from "react";

interface PropType {
  fileCardId: string;
}

const PDFPrint = (props: PropType): ReactElement<PropType> => {
  return (
    <div>
      <a href={process.env.REACT_APP_API_URL + "admin/pdf/" + props.fileCardId}>
        Download PDF
      </a>
    </div>
  );
};

export default PDFPrint;
