import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDDropDown from "../../UI/MDDropDown/MDDropDown";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { LegalForm } from "../../_types_/CompanySetupType";
import { ALL_COMPANIES } from "../../_constants_/constants";
import {
  setBillToCompanyRegistrationNumber,
  setBillToDistrictCourt,
  setBillToLegalForm,
  setBillToLegalFormText,
} from "../../_reducers_/FileCardSlice";

interface PropType {
  legalForms: LegalForm[];
  control: any;
  errors: any;
}

const LegalFormFields = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const dispatch = useDispatch();
  const [t] = useTranslation("general");
  const [legalFormCompanyRegistrationNumberDisabled, setLegalFormCompanyRegistrationNumberDisabled] = useState(false);
  const [legalFormDistrictCourtDisabled, setLegalFormDistrictCourtDisabled] = useState(false);

  const [showLegalFormText, setShowLegalFormText] = useState(false);

  const [legalFormCodeWithLegalFormTextEnabled, setLegalFormCodeWithLegalFormTextEnabled] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    const company = ALL_COMPANIES.find((c) => c.code === fileCard.fileCard.companyCode);
    if (company?.legalFormCompanyRegistrationNumberDisabled) {
      setLegalFormCompanyRegistrationNumberDisabled(true);
    }
    if (company?.legalFormDistrictCourtDisabled) {
      setLegalFormDistrictCourtDisabled(true);
    }
    setLegalFormCodeWithLegalFormTextEnabled(company?.legalFormCodeWithLegalFormTextEnabled);
  }, [fileCard.fileCard.companyCode]);

  useEffect(() => {
    if (legalFormCodeWithLegalFormTextEnabled !== undefined) {
      if (legalFormCodeWithLegalFormTextEnabled === fileCard.fileCard.billToAddress.legalform) {
        setShowLegalFormText(true);
      } else {
        setShowLegalFormText(false);
      }
    }
  }, [legalFormCodeWithLegalFormTextEnabled, fileCard.fileCard.billToAddress.legalform]);

  return (
    <>
      <MDDropDown
        name="legalForms"
        error={props.errors.legalForms}
        label={t("billToAddress.legalForms.label")}
        items={props.legalForms.map(function (s) {
          return {
            code: String(s.code),
            value: t("billToAddress.legalForms.option" + s.code),
          };
        })}
        control={props.control}
        value={fileCard.fileCard.billToAddress.legalform ?? ""}
        onChangeSetValue={(event: string): void => {
          dispatch(setBillToLegalForm(Number(event)));
        }}
        validationText={t("validation.requiredField")}
        defaultMenuItem={true}
        defaultMenuItemKey=""
        defaultMenuItemValue=""
      />
      {fileCard.fileCard.billToAddress.legalform !== null && fileCard.fileCard.billToAddress.legalform !== 0 ? (
        <>
          {!legalFormDistrictCourtDisabled ? (
            <MDTextField
              label={t("billToAddress.districtCourt")}
              name="districtCourt"
              error={props.errors.districtCourt}
              control={props.control}
              rules={{
                required: {
                  message: t("validation.requiredField"),
                  value: true,
                },
              }}
              value={fileCard.fileCard.billToAddress?.districtCourt ?? ""}
              onChangeSetValue={(e: string): void => {
                dispatch(setBillToDistrictCourt(e));
              }}
            />
          ) : undefined}
          {!legalFormCompanyRegistrationNumberDisabled ? (
            <MDTextField
              label={t("billToAddress.companyRegistrationNumber")}
              name="companyRegistrationNumber"
              error={props.errors.companyRegistrationNumber}
              control={props.control}
              rules={{
                required: {
                  message: t("validation.requiredField"),
                  value: true,
                },
              }}
              value={fileCard.fileCard.billToAddress?.companyRegistrationNumber ?? ""}
              onChangeSetValue={(e: string): void => {
                dispatch(setBillToCompanyRegistrationNumber(e));
              }}
              prefixText="HR"
            />
          ) : undefined}
          {showLegalFormText ? (
            <MDTextField
              label={t("billToAddress.legalForms.legalformText")}
              name="legalformText"
              error={props.errors.legalformText}
              control={props.control}
              rules={{
                required: {
                  message: t("validation.requiredField"),
                  value: true,
                },
                maxLength: {
                  message: t("validation.maxNumberDigits", { number: 100 }),
                  value: 100,
                },
              }}
              value={fileCard.fileCard.billToAddress?.legalformText ?? ""}
              onChangeSetValue={(e: string): void => {
                dispatch(setBillToLegalFormText(e));
              }}
            />
          ) : undefined}
        </>
      ) : undefined}
    </>
  );
};

export default LegalFormFields;
