import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction } from "react";
import { ALL_COMPANIES } from "../_constants_/constants";
import { fileCardService } from "../_services_/fileCard.service";
import { FileCardFromServer } from "../_types_/actions/FileCardAction";
import { FileCardType } from "../_types_/FileCardType";
import { ShipToType } from "../_types_/ShipToType";
import { getTokenFromUrl } from "./QueryStringUtil";
import {
  addShipTo,
  setBillToAccountsDepartmentPhoneNumberCallingCode,
  setBillToCountryISOAlpha3,
  setBillToDirectPlatformEmail,
  setBillToLegalName,
  setBillToOrderConfirmationEmail,
  setBillToSmsCodeCellPhoneNumberCallingCode,
  setBillToStatementOfAccountEmail,
  setBillToWholesaleAgreementEmail,
  setBrands,
  setBudgetSplitPerBrand,
  setBusinessTradingPartnerId,
  setCompanyCodeFromServer,
  setShipToCountry,
  setShipToDirectPlatformEmail,
  setShipToInvoiceEmail,
  setShipToOrderConfirmationEmail,
  setShipToStoreCellPhoneNumberCallingCode,
  setShipToStoreEmail,
  setShipToStoreName,
  setShipToStorePhoneNumberCallingCode,
} from "../_reducers_/FileCardSlice";

export function storeBrandsFromBackend(dispatch: Dispatch<any>, response: AxiosResponse<FileCardFromServer>) {
  dispatch(setBrands(response.data.brands));
}

export function storeBudgetSplitPerBrandsFromBackend(
  dispatch: Dispatch<any>,
  response: AxiosResponse<FileCardFromServer>
) {
  dispatch(setBudgetSplitPerBrand(response.data.budgetSplitPerBrand));
}

export function sendCustomerFileCard(
  fileCard: FileCardType,
  setFileCardSent: React.Dispatch<React.SetStateAction<boolean>>,
  setAdminUserCreationErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  const fileCard2: FileCardType = {
    ...fileCard,
    guaranteeAmount: fileCard.guaranteeAmount === null ? null : Number(fileCard.guaranteeAmount),
    budget: fileCard.budget === null ? null : Number(fileCard.budget),
  };
  setLoading(true);
  fileCardService
    .sendFileCard(getTokenFromUrl(), fileCard2)
    .then((response: AxiosResponse) => {
      setAdminUserCreationErrorMessage(response.data);
      setFileCardSent(true);
    })
    .finally(() => {
      setLoading(false);
    });
}

export const sendAdminFileCard = (
  fileCard: FileCardType,
  approve: boolean,
  setFileCardSent: Dispatch<SetStateAction<boolean>>,
  setStatus: Dispatch<SetStateAction<number>>,
  setSaved: Dispatch<SetStateAction<boolean>>
) => {
  const fileCard2: FileCardType = {
    ...fileCard,
    guaranteeAmount: fileCard.guaranteeAmount === null ? null : Number(fileCard.guaranteeAmount),
    budget: fileCard.budget === null ? null : Number(fileCard.budget),
  };
  fileCardService.sendAdminFileCard(getTokenFromUrl(), fileCard2).then(() => {
    if (approve) {
      fileCardService
        .sendAdminApproveFileCard(getTokenFromUrl())
        .catch(() => {})
        .then(() => {
          setFileCardSent(true);
          setStatus(2);
        })
        .finally(() => {});
    } else {
      setSaved(true);
    }
  });
};

export const initializeShipToEmails = (dispatch: Dispatch<any>, email: string, shipToId: any) => {
  dispatch(setShipToStoreEmail({ shipToId: shipToId, storeEmail: email }));

  dispatch(setShipToInvoiceEmail({ shipToId: shipToId, invoiceEmail: email }));

  dispatch(setShipToOrderConfirmationEmail({ shipToId: shipToId, orderConfirmationEmail: email }));

  dispatch(setShipToDirectPlatformEmail({ shipToId: shipToId, directPlatformEmail: email }));
};

export function initializeFieldsFromProspectData(
  dispatch: Dispatch<any>,
  response: AxiosResponse<FileCardFromServer>,
  shipToId: any
) {
  if (response.data.shipTos.length === 0) {
    dispatch(addShipTo(shipToId));
    initializeShipToEmails(dispatch, response.data.prospect.email, shipToId);
    dispatch(setShipToStoreName({ shipToId: shipToId, storeName: response.data.prospect.name }));
  }
  const company = ALL_COMPANIES.find((c) => c.code === response.data.companyCode);

  dispatch(setBillToLegalName(response.data.prospect.name));
  dispatch(setBillToWholesaleAgreementEmail(response.data.prospect.email));
  if (company != null && company.hideStatementOfAccountEmail !== true) {
    dispatch(setBillToStatementOfAccountEmail(response.data.prospect.email));
  }
  dispatch(setBillToDirectPlatformEmail(response.data.prospect.email));
  dispatch(setBillToOrderConfirmationEmail(response.data.prospect.email));
  dispatch(setBusinessTradingPartnerId(response.data.businessTradingPartnerId));
}

export function initializeCallingCodeFields(dispatch: Dispatch<any>, defaultCallingCode: string, shipToId: any) {
  dispatch(setShipToStorePhoneNumberCallingCode({ shipToId: shipToId, callingCode: defaultCallingCode }));
  dispatch(setShipToStoreCellPhoneNumberCallingCode({ shipToId: shipToId, callingCode: defaultCallingCode }));
  dispatch(setBillToSmsCodeCellPhoneNumberCallingCode(defaultCallingCode));
  dispatch(setBillToAccountsDepartmentPhoneNumberCallingCode(defaultCallingCode));
}

export function setCompanyCodeSpecificDefaultValues(companyCode: number, dispatch: Dispatch<any>, shipToId: any) {
  const company = ALL_COMPANIES.find((c) => c.code === companyCode);
  if (company) {
    initializeCallingCodeFields(dispatch, company.callingCode, shipToId);
  }
}

export function storeCompanyCodeInFileCard(dispatch: Dispatch<any>, companyCode: number) {
  dispatch(setCompanyCodeFromServer(companyCode));
}

export function isBillingEnabledForCompany(companyCode: number): boolean {
  const company = ALL_COMPANIES.find((c) => c.code === companyCode);
  if (company) {
    return company.billing;
  }
  return true;
}

export const initializeAdminFileCard = (dispatch: Dispatch<any>, shipToObj: ShipToType | undefined) => {
  if (shipToObj?.deliveryAddress === null) {
    dispatch(setShipToCountry({ shipToId: shipToObj?.shipToId, countryISOAlpha3: "DNK" }));

    dispatch(setBillToCountryISOAlpha3("DNK"));
  }
};
