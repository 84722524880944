import { useDispatch, useSelector } from "react-redux";
import { SigningAction } from "../_types_/actions/SigningAction";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { properties } from "../properties";
import { DocuSignEnvelope } from "../_types_/DocuSign";
import { setDocuSignEnvelope } from "../_reducers_/SigningSlice";
import { Button, LinearProgress } from "@mui/material";
import { getTokenFromUrl } from "../utils/QueryStringUtil";
import Recipients from "./Recipients.tsx/Recipients";
import { useTranslation } from "react-i18next";

const Signing = () => {
  const signing = useSelector((state: { signing: SigningAction }) => state.signing);
  const {
    handleSubmit,
    setValue,
    trigger,
    formState,
    formState: { errors },
    control,
  } = useForm();
  const { i18n } = useTranslation("general");
  const [loading, setLoading] = useState(true);
  const [apiUpdateError, setApiUpdateError] = useState(false);
  const [apiFetchError, setApiFetchError] = useState(false);
  const [apiUpdateOk, setApiUpdateOk] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage("en");
    const fileCardId = getTokenFromUrl();
    setLoading(true);
    axios({
      url: properties.serviceApiURL + "admin/filecard/docuSignSigning/" + fileCardId,
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
    })
      .then((response: AxiosResponse<DocuSignEnvelope>): void => {
        dispatch(setDocuSignEnvelope({ docuSignEnvelope: response.data }));
        setLoading(false);
      })
      .catch((error: AxiosError): void => {
        setApiFetchError(true);
      })
      .finally((): void => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const submitForm = (data: Record<string, any>): void => {
    setLoading(true);
    axios({
      url: properties.serviceApiURL + "DocuSign/updateRecipients",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: JSON.stringify(signing.docuSignEnvelope),
    })
      .then((): void => {
        setApiUpdateOk(true);
        setLoading(false);
      })
      .catch((error: AxiosError): void => {
        setApiUpdateError(true);
      })
      .finally((): void => {
        setLoading(false);
      });
  };

  return loading ? (
    <LinearProgress />
  ) : apiFetchError ? (
    <div>Error fetching signing data</div>
  ) : signing.docuSignEnvelope?.signingStatus === "envelope-completed" ? (
    <div>The signing is completed, so it is not possible to edit it</div>
  ) : signing.docuSignEnvelope?.signingStatus === "envelope-corrected" ? (
    <div>Correction has been sent to DocuSign, but DocuSign has not responded yet</div>
  ) : apiUpdateOk ? (
    <div>The signing was updated</div>
  ) : apiUpdateError ? (
    <div style={{ color: "red" }}>Error when updating signing</div>
  ) : (
    <div>
      <h1>Edit Signing</h1>
      <div>
        You can resend an evelope and change the name, email and phone number of all reciepient of the DocuSign
        envelope.
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <Recipients control={control} errors={errors} formState={formState} setValue={setValue} trigger={trigger} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="primary" type="submit" style={{ width: "260px", marginTop: "80px" }}>
            SAVE AND SEND ENVELOPE
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Signing;
