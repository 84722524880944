import React, { ReactElement } from "react";
import { getSalestoolLink } from "../../utils/Enviroment";

const RedirectionToSalestool = (): ReactElement => {
  return (
    <div>
      <p>
        The process for creating a new filecard have been changed. For creating
        a filecard you need to go to the sales tool and send it to the customer
        directly from the prospects menu (Link: &nbsp;
        <a href={getSalestoolLink() + "showProspects"} rel="noopener noreferrer" target="_blank">
          {getSalestoolLink() + "showProspects"}
        </a>
        )
        <br />
        <br />
        In case of any questions please go to:
        <a
          href={getSalestoolLink() + "support"}
          rel="noopener noreferrer"
          target="_blank"
        >
          {getSalestoolLink() + "support"}
        </a>
      </p>
    </div>
  );
};

export default RedirectionToSalestool;
