import { Clear } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDProductLineDropDown from "../../../UI/MDProductLineDropDown/MDProductLineDropDown";
import { PLEASE_SELECT_PRODUCT_LINE_CODE } from "../../../_constants_/constants";
import { CreateNewFileCardAction } from "../../../_types_/actions/CreateNewFileCardAction";
import { AllProductLinesType } from "../../../_types_/AllProductLinesType";
import { ProductLineType } from "../../../_types_/ProductLineType";
import classes from "./BrandingBrand.module.scss";
import BrandingProductLine from "./ProductLine/BrandingProductLine";
import { ProductlinePayload } from "../../../_types_/reduxTypes/ProductlinePayload";
import {
  addSelectedProductLineCode,
  removeBrand,
  setSelectedProductlineCode,
  validateBranding,
} from "../../../_reducers_/CreateNewFileCardSlice";

interface PropType {
  name: string;
  code: string;
  control: any;
  errors: any;
}

const BrandingBrand = (props: PropType): ReactElement<PropType> => {
  const createNewFileCard = useSelector(
    (state: { createNewFileCard: CreateNewFileCardAction }) => state.createNewFileCard
  );

  const [selectedProductLineCode, setSelectedProductLineCode] = useState(PLEASE_SELECT_PRODUCT_LINE_CODE);

  const dispatch = useDispatch();

  const chosenBrand: AllProductLinesType | undefined = createNewFileCard.BasicMasterData.AllProductLines.find(
    (pl) => pl.code === props.code
  );

  const selectedProductLines = createNewFileCard.NewFileCard.Brands
    ? createNewFileCard.NewFileCard.Brands.find((brand) => brand.code === props.code)
    : undefined;

  return (
    <fieldset className={classes.mainContainer}>
      <legend className={classes.brandHeader}>{props.name.toUpperCase()}</legend>

      <div className={classes.closeIcon}>
        <Clear
          onClick={() => {
            dispatch(removeBrand({ name: props.name, code: props.code }));

            dispatch(validateBranding());
          }}
        />
      </div>
      <div className={classes.dropDownContainer}>
        <MDProductLineDropDown
          label="Product Lines"
          name="productLines"
          control={props.control}
          error={props.errors.productLine}
          className={classes.dropDown}
          defaultMenuItem={true}
          defaultMenuItemKey={PLEASE_SELECT_PRODUCT_LINE_CODE}
          defaultMenuItemValue="Please choose a product line"
          value={selectedProductLineCode}
          onChange={(event: string): void => {
            dispatch(setSelectedProductlineCode(event));

            setSelectedProductLineCode(event);
          }}
          items={chosenBrand?.productLines.map((productLine) => {
            return {
              code: productLine.code,
              value: productLine.name.toUpperCase(),
            };
          })}
          chosenBrand={chosenBrand}
          selectedProductLines={selectedProductLines}
        />

        <Button
          className={classes.addButton}
          style={{ height: "37px" }}
          variant="contained"
          color="primary"
          onClick={() => {
            if (selectedProductLineCode && selectedProductLineCode !== PLEASE_SELECT_PRODUCT_LINE_CODE) {
              const selectedProductline = chosenBrand?.productLines?.find((pl) => pl.code === selectedProductLineCode);

              const productlinePayload: ProductlinePayload = {
                code: selectedProductLineCode,
                name: selectedProductline?.name,
                brandCode: props.code,
              };

              dispatch(addSelectedProductLineCode(productlinePayload));

              setSelectedProductLineCode(PLEASE_SELECT_PRODUCT_LINE_CODE);

              dispatch(validateBranding());
            }
          }}
        >
          <AddIcon style={{ marginLeft: 10 }} />
        </Button>
      </div>
      <Table className={classes.Tabel}>
        <TableHead>
          <TableRow>
            <TableCell>Product Line</TableCell>
            <TableCell align="right">Sales Persons</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedProductLines
            ? selectedProductLines.productLines
              ? [...selectedProductLines.productLines]
                  .sort((a: ProductLineType, b: ProductLineType): number => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((row) => (
                    <BrandingProductLine
                      key={row.code}
                      productLine={row}
                      brandCode={props.code}
                      control={props.control}
                      errors={props.errors}
                    />
                  ))
              : undefined
            : undefined}
        </TableBody>
      </Table>
    </fieldset>
  );
};

export default BrandingBrand;
