import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ALL_COMPANIES, PLEASE_SELECT_BRAND_CODE } from "../_constants_/constants";
import { CreateNewFileCardAction } from "../_types_/actions/CreateNewFileCardAction";
import { ProductlinePayload } from "../_types_/reduxTypes/ProductlinePayload";
import { BasicBrandType } from "../_types_/BasicBrandType";
import { SalesPersonsPayload } from "../_types_/reduxTypes/SalespersonsPayload";

export const createNewFileCardInitialState: CreateNewFileCardAction = {
  NewFileCard: {
    Prospect: {
      name: "",
      email: "",
    },
    CompanyCode: 0,
    Brands: undefined,
  },
  type: "",
  BasicMasterData: {
    AllBrands: [{ code: "00", name: "Error" }],
    AllProductLines: [
      {
        code: "00",
        productLines: [
          {
            code: "0000",
            name: "Error",
            selected: false,
            salesPersons: undefined,
          },
        ],
      },
    ],
    AllCompanies: ALL_COMPANIES,
  },
  SelectedBrandCode: "0",
  SelectedBrandName: "",
  SelectedProductLineCode: "0",
  SelectedSalesPersons: [],
  IsBrandingValid: false,
  SendButtonClicked: false,
  IsCompanySelected: false,
};

const CreateNewFileCardSlice = createSlice({
  name: "CreateNewFileCardSlice",
  initialState: createNewFileCardInitialState,
  reducers: {
    validateBranding: (state) => {
      let isBrandingValid = true;
      if (state.NewFileCard.Brands === undefined || state.NewFileCard.Brands.length === 0) {
        isBrandingValid = false;
      }
      const brands = state.NewFileCard.Brands ? state.NewFileCard.Brands : [];
      for (let i = 0; i < brands.length; i++) {
        if (brands[i].productLines === undefined) {
          isBrandingValid = false;
        }
      }
      state.IsBrandingValid = isBrandingValid;
    },
    setSendButtonClicked: (state) => {
      state.SendButtonClicked = true;
    },

    setIsCompanySelected: (state, action) => {
      state.IsCompanySelected = action.payload;
    },

    setAllProductLines: (state, action) => {
      state.BasicMasterData.AllProductLines = action.payload;
    },

    setAllBrands: (state, action) => {
      state.BasicMasterData.AllBrands = action.payload;
    },

    setCustomerName: (state, action) => {
      state.NewFileCard.Prospect.name = action.payload;
    },

    setCompanyCode: (state, action) => {
      state.NewFileCard.CompanyCode = action.payload;
    },

    setCustomerEmail: (state, action) => {
      state.NewFileCard.Prospect.email = action.payload;
    },

    setSelectedBrandCode: (state, action) => {
      state.SelectedBrandCode = action.payload;
    },
    addSelectedBrandCode: (state, action: PayloadAction<Partial<BasicBrandType>>) => {
      const { name, code } = action.payload;
      state.BasicMasterData.AllBrands = state.BasicMasterData.AllBrands.filter((brand) => brand.code !== code);
      if (state.NewFileCard.Brands === undefined) {
        state.NewFileCard.Brands = [];
      }

      if (state.NewFileCard.Brands && name && code) {
        state.NewFileCard.Brands.push({ code: code, name: name ?? "", productLines: [] });
      }

      state.SelectedBrandCode = PLEASE_SELECT_BRAND_CODE;
    },

    removeBrand: (state, action: PayloadAction<Partial<BasicBrandType>>) => {
      const { name, code } = action.payload;

      if (name && code) {
        state.BasicMasterData.AllBrands.push({
          code: code,
          name: name,
        });
        state.NewFileCard.Brands = state.NewFileCard.Brands?.filter((brand) => brand.code !== code);

        state.SelectedBrandCode = PLEASE_SELECT_BRAND_CODE;
      }
    },

    setSelectedProductlineCode: (state, action) => {
      state.SelectedProductLineCode = action.payload;
    },

    addSelectedProductLineCode: (state, action: PayloadAction<ProductlinePayload>) => {
      const { name, brandCode, code } = action.payload;

      const currentBrand = (state.NewFileCard.Brands ? state.NewFileCard.Brands : []).find(
        (brands) => brands.code === brandCode
      );

      if (currentBrand && currentBrand.productLines) {
        currentBrand.productLines.push({ code: code, name: name ?? "", selected: true, salesPersons: [] });
      }
    },

    setSalesPersonForProductline: (state, action: PayloadAction<SalesPersonsPayload>) => {
      const { salesPersons, brandCode, productlineCode } = action.payload;

      const foundBrand = state.NewFileCard.Brands?.find((brand) => brand.code === brandCode);

      if (foundBrand) {
        const foundProductLine = foundBrand.productLines?.find((productLine) => productLine.code === productlineCode);
        if (foundProductLine) {
          foundProductLine.salesPersons = salesPersons;
        }
      }
    },
  },
});

export const {
  validateBranding,
  setSendButtonClicked,
  setIsCompanySelected,
  setAllProductLines,
  setAllBrands,
  setCustomerName,
  setCompanyCode,
  setCustomerEmail,
  setSelectedBrandCode,
  addSelectedBrandCode,
  removeBrand,
  setSelectedProductlineCode,
  addSelectedProductLineCode,
  setSalesPersonForProductline,
} = CreateNewFileCardSlice.actions;

export default CreateNewFileCardSlice.reducer;
