import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

export interface DropDownItem {
  code: string;
  value: string;
  description?: string;
}

interface PropTypes {
  name: string;
  label: string;
  value: any;
  error: any;
  control: any;
  className?: any;
  onChangeSetValue: (value: any) => void;
  defaultMenuItem?: boolean;
  defaultMenuItemKey?: string;
  defaultMenuItemValue?: string;
  items: DropDownItem[];
  validationText: string;
}

const MDDropDown: React.FC<PropTypes> = (props: PropTypes) => {
  return (
    <>
      <Controller
        control={props.control}
        render={({ field }) => (
          <TextField
            select
            label={props.label}
            className={props.className}
            inputRef={field.ref}
            variant="outlined"
            value={props.value}
            fullWidth
            error={props.error ? true : false}
            helperText={props.error && props.error.message}
            onChange={(event) => {
              props.onChangeSetValue(event.target.value);
              field.onChange(event.target.value);
            }}
          >
            {props.defaultMenuItem && (
              <MenuItem key={props.defaultMenuItemKey} value={props.defaultMenuItemKey}>
                {props.defaultMenuItemValue}
              </MenuItem>
            )}
            {props.items.map((item) => (
              <MenuItem key={item.code} value={item.code}>
                {item.value}
                {item.description && (
                  <>
                    <br />
                    {item.description}
                  </>
                )}
              </MenuItem>
            ))}
          </TextField>
        )}
        name={props.name}
        defaultValue={props.value}
        rules={
          props.validationText
            ? {
                validate: (value: any) => {
                  return value !== "" && value !== undefined ? true : props.validationText;
                },
              }
            : undefined
        }
      />
    </>
  );
};

export default MDDropDown;
