import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { EMAIL_VALIDATION_REGEX } from "../../_constants_/constants";
import MDTextField from "../MDTextField/MDTextField";

interface PropType {
  control: any;
  setValue: any;
  emailLabel: string;
  emailName: string;
  emailError: any;
  emailValue: string | undefined;
  emailOnChange: (value: any) => void;
  disabled?: boolean;
}

const MDEmailField = (props: PropType): ReactElement<PropType> => {
  const [t] = useTranslation("general");

  const { setValue, emailValue, emailName } = props;

  const validateEmail = (value: string) => {
    if (value === null) {
      return true;
    } else {
      const re = EMAIL_VALIDATION_REGEX;
      return re.test(String(value).toLowerCase()) ? true : t("validation.wrongEmailFormat");
    }
  };

  useEffect(() => {
    setValue(emailName, emailValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValue]);

  return (
    <>
      <MDTextField
        label={props.emailLabel}
        name={props.emailName}
        control={props.control}
        error={props.emailError}
        value={props.emailValue}
        onChangeSetValue={props.emailOnChange}
        rules={{
          required: {
            message: t("validation.requiredField"),
            value: true,
          },
          validate: validateEmail,
        }}
        disabled={props.disabled ? props.disabled : false}
      />
    </>
  );
};

export default MDEmailField;
