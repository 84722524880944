import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MDAutoComplete from "../../UI/MDAutoComplete/MDAutoComplete";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { TaxJurisdictionType } from "../../_types_/serverTypes/TaxJurisdictionType";

interface PropType {
  error: any;
  control: any;
  setValue: any;
  name: string;
  value: number;
  onChangeSetValue: any;
}

const TaxJurisdictions = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const [t] = useTranslation("general");

  const [taxJurisdictionValue, setTaxJurisdictionValue] = useState<TaxJurisdictionType>();

  useEffect(() => {
    if (fileCard.allTaxJurisdictions.length > 0) {
      const taxJurisdiction = fileCard.allTaxJurisdictions.find((tj) => tj.id === props.value);
      setTaxJurisdictionValue(taxJurisdiction);
    }
  }, [fileCard.allTaxJurisdictions, props.value]);

  return (
    <MDAutoComplete
      control={props.control}
      multiple={false}
      label={t("billToAddress.billToTaxJurisdiction")}
      name={props.name}
      error={props.error}
      value={taxJurisdictionValue || null}
      items={fileCard.allTaxJurisdictions}
      onChangeSetValue={props.onChangeSetValue}
      setValue={props.setValue}
      getOptionLabel={(option: any) => {
        if (option.name === "") {
          return "";
        } else {
          return `${option.name} - ${option.code}`;
        }
      }}
      validationText={t("validation.requiredField")}
    />
  );
};

export default TaxJurisdictions;
