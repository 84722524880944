import React, { ReactElement } from "react";
import { ProductLineType } from "../../../_types_/ProductLineType";
import classes from "./Brand.module.scss";

interface PropType {
  brandName: string;
  productLines: ProductLineType[] | undefined;
}

const Brand = (props: PropType): ReactElement<PropType> => {
  return (
    <table className={classes.tabel}>
      <tbody>
        <tr>
          <td className={classes.brandName}>{props.brandName.toUpperCase()}</td>
        </tr>
        <tr>
          <td>
            <table className={classes.productLineTable}>
              <tbody>
                {props.productLines
                  ? props.productLines.map((row) => (
                      <tr key={row.code}>
                        <td className={classes.productLine}>
                          {row.name.toUpperCase()}
                        </td>
                        <td className={classes.salesPerson}>
                          {row.salesPersons
                            ? row.salesPersons.map((sp, index) => (
                                <span key={sp.employeeNumber}>
                                  {sp.name}{" "}
                                  {row.salesPersons
                                    ? row.salesPersons.length - 1 !== index
                                      ? ","
                                      : ""
                                    : ""}{" "}
                                </span>
                              ))
                            : undefined}
                        </td>
                      </tr>
                    ))
                  : undefined}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Brand;
