import React, { ReactElement } from "react";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";

interface PropType {
  companyCode: number;
}

const FileCardExpired = (props: PropType): ReactElement<PropType> => {
  const [t_c] = useTranslation(props.companyCode.toString());

  return <div>{ReactHtmlParser(t_c("errors.fileCardExpired"))}</div>;
};

export default FileCardExpired;
