import EditIcon from "@mui/icons-material/Edit";
import {
  LinearProgress,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BrandsTable from "../MainPage/BrandsTable/BrandsTable";
import { ALL_COMPANIES } from "../_constants_/constants";
import { fileCardService } from "../_services_/fileCard.service";
import { FileCardServerType } from "../_types_/serverTypes/FileCardServerType";

interface tabelDataType {
  data: any;
  page: number;
  total: number;
}

const STATUS_WAITING_FOR_CUSTOMER = 0;
const STATUS_READY_FOR_APPROVAL = 1;
const STATUS_ACCEPTED = 2;

const FileCardList = (): ReactElement => {
  const [count, setCount] = useState(1);
  const [rowsPerPage] = useState(100);
  const [data, setData] = useState([["Loading Data..."]]);
  const [fileCardsFromServer, setFileCardsFromServer] =
    useState<FileCardServerType[]>();

  const { i18n } = useTranslation("general");

  const [columns] = useState([
    {
      name: "prospect",
      label: "Name",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return value ? value.name : "";
        },
      },
    },
    {
      name: "prospect",
      label: "E-Mail",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return value ? value.email : "";
        },
      },
    },
    {
      name: "companyCode",
      label: "Company",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return ALL_COMPANIES.find((c) => c.code === value)?.name;
        },
      },
    },
    {
      name: "billToAddress",
      label: "Postal Code",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return value ? value.postalCode : "";
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          switch (value) {
            case STATUS_WAITING_FOR_CUSTOMER:
              return "Awaiting customer";
            case STATUS_READY_FOR_APPROVAL:
              return "Ready for approval";
            case STATUS_ACCEPTED:
              return "Approved";
          }
        },
      },
    },
    {
      name: "createdByName",
      label: "Created By",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return value ? value : "";
        },
      },
    },
    {
      name: "id",
      label: "Edit",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const statusCode = tableMeta.rowData[4];
          if (statusCode === STATUS_READY_FOR_APPROVAL)
            return (
              <a href={"/admin/edit/" + value} aria-label="Edit customer">
                <EditIcon color="secondary" />
              </a>
            );
        },
      },
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage("en");
    const xhrRequest = (page: number) => {
      return new Promise<tabelDataType>((resolve, reject) => {
        fileCardService.getFrenchFileCards().then((res) => {
          const dataFromApi: FileCardServerType[] = res.data;
          setFileCardsFromServer(dataFromApi);
          let fullData = dataFromApi.filter((d) => d.status < 2);
          const total = fullData.length;
          let data = fullData;

          resolve({
            data,
            total,
            page,
          });
        });
      });
    };

    const getData = (page: any) => {
      setIsLoading(true);
      xhrRequest(page).then((res) => {
        setData(res.data);
        setIsLoading(false);
        setCount(res.total);
      });
    };
    getData(0);
  }, [i18n]);

  const options: MUIDataTableOptions = {
    expandableRows: true,
    expandableRowsHeader: false,
    search: false,
    download: false,
    viewColumns: false,
    print: false,
    expandableRowsOnClick: false,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Paper
              style={{
                width: "620px",
                margin: "20px 50px",
                padding: "20px",
                boxShadow:
                  "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
              }}
            >
              <BrandsTable
                style={{ marginTop: "0px" }}
                brands={
                  fileCardsFromServer
                    ? fileCardsFromServer[rowMeta.dataIndex].brands
                    : undefined
                }
              />
            </Paper>
          </TableCell>
        </TableRow>
      );
    },
    filter: false,
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    selectableRows: "none",
  };

  return (
    <div style={{ marginTop: "-100px" }}>
      {" "}
      {isLoading ? (
        <LinearProgress style={{ width: "200px" }} />
      ) : (
        <MUIDataTable
          title={<Typography variant="h6">File Cards</Typography>}
          data={data}
          columns={columns}
          options={options}
        />
      )}
    </div>
  );
};

export default FileCardList;
