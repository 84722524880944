import RedirectionToSalestool from "./MainPage/RedirectionToSalestool/RedirectionToSalestool";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CreateNewFileCard from "./CreateFileCard/CreateNewFileCard";
import FileCardList from "./FileCardList/FileCardList";
import MainPage from "./MainPage/MainPage";
import PageNotAvailable from "./MainPage/PageNotAvailable/PageNotAvailable";
import ResendSigning from "./ResendSigning/ResendSigning";
import { isFileCardListUrl } from "./utils/QueryStringUtil";
import Signing from "./Signing/Signing";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div className={isFileCardListUrl() ? "" : "main-container"}>
        <Routes>
          <Route path="/create" element={<RedirectionToSalestool />} />
          <Route path="/createnewfilecard" element={<CreateNewFileCard />} />
          <Route path="/resend/signing" element={<ResendSigning />} />
          <Route path="/admin/list" element={<FileCardList />} />
          <Route path="/admin/edit/:id" element={<MainPage />} />
          <Route path="/admin/signing/:id" element={<Signing />} />
          <Route path="/admin/:id" element={<MainPage />} />
          <Route path="/:id" element={<MainPage />} />
          <Route path="*" element={<PageNotAvailable />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
