export const getTodaysDate = (): string => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
};

export const removeTimeFromDate = (date: Date | null): Date | null => {
  if (date === null) {
    return null;
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
