import { TableCell, TableRow } from "@mui/material";
import { AxiosResponse } from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDDropDown from "../../../../UI/MDDropDown/MDDropDown";
import { basicDataService } from "../../../../_services_/basicData.service";
import { CreateNewFileCardAction } from "../../../../_types_/actions/CreateNewFileCardAction";
import { ProductLineType } from "../../../../_types_/ProductLineType";
import { SalesPersonType } from "../../../../_types_/SalesPersonType";
import { setSalesPersonForProductline } from "../../../../_reducers_/CreateNewFileCardSlice";

interface PropType {
  productLine: ProductLineType;
  brandCode: string;
  control: any;
  errors: any;
}

const BrandingProductLine = (props: PropType): ReactElement<PropType> => {
  const [selectSalesPerson, setSelectedSalesPerson] = useState<string>("");

  const createNewFileCard = useSelector(
    (state: { createNewFileCard: CreateNewFileCardAction }) => state.createNewFileCard
  );

  useEffect(() => {
    let salesPersons: SalesPersonType[] = [{ employeeNumber: 0, name: "test" }];
    basicDataService
      .getSalesPersonsForProductLine(props.productLine.code, String(createNewFileCard.NewFileCard.CompanyCode))
      .then((response: AxiosResponse<ProductLineType>): void => {
        salesPersons = response.data.salesPersons ? response.data.salesPersons : salesPersons;
        setSalesPersons(response.data.salesPersons);
      })
      .finally((): void => {});
  }, [props.productLine.code, createNewFileCard.NewFileCard.CompanyCode]);

  const [salesPersons, setSalesPersons] = useState<SalesPersonType[]>();

  const dispatch = useDispatch();

  return (
    <TableRow key={props.productLine.code}>
      <TableCell component="th" scope="row" style={{ width: "40%" }}>
        {props.productLine.name.toUpperCase()}
      </TableCell>
      <TableCell align="right" style={{ textAlign: "left" }}>
        {salesPersons && (
          <MDDropDown
            control={props.control}
            error={props.errors.productline}
            label="Sales persons"
            name="salesPersons"
            value={selectSalesPerson}
            onChangeSetValue={(event: string): void => {
              dispatch(
                setSalesPersonForProductline({
                  productlineCode: props.productLine.code,
                  brandCode: props.brandCode,
                  salesPersons: [{ employeeNumber: Number(event), name: "" }],
                })
              );

              setSelectedSalesPerson(String(event));
            }}
            items={salesPersons.map((salesPeron) => {
              return {
                code: salesPeron.employeeNumber.toString(),
                value: salesPeron.name,
              };
            })}
            validationText=""
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default BrandingProductLine;
