import AddIcon from "@mui/icons-material/Add";
import { Button, SelectChangeEvent } from "@mui/material";
import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDDropDown from "../../UI/MDDropDown/MDDropDown";
import { PLEASE_SELECT_BRAND_CODE } from "../../_constants_/constants";
import { CreateNewFileCardAction } from "../../_types_/actions/CreateNewFileCardAction";
import { BasicBrandType } from "../../_types_/BasicBrandType";
import { BrandType } from "../../_types_/BrandType";
import BrandingBrand from "./Brand/BrandingBrand";
import styling from "./Branding.module.scss";
import { addSelectedBrandCode, setSelectedBrandCode, validateBranding } from "../../_reducers_/CreateNewFileCardSlice";

interface PropType {
  control: any;
  errors: any;
}

const Branding = (props: PropType): ReactElement<PropType> => {
  const createNewFileCard = useSelector(
    (state: { createNewFileCard: CreateNewFileCardAction }) => state.createNewFileCard
  );

  const sortedBrands = [...createNewFileCard.BasicMasterData.AllBrands].sort(
    (a: BasicBrandType, b: BasicBrandType): number => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }
  );

  const dispatch = useDispatch();

  return (
    <div>
      <h2>Add Brand</h2>
      {createNewFileCard.BasicMasterData.AllBrands.length > 0 ? (
        <div className={styling.topContainer}>
          <MDDropDown
            label="Brands"
            name="brands"
            className={styling.dropDown}
            control={props.control}
            error={props.errors.brands}
            value={createNewFileCard.SelectedBrandCode}
            defaultMenuItem={true}
            defaultMenuItemKey={PLEASE_SELECT_BRAND_CODE}
            defaultMenuItemValue="Please choose a brand"
            validationText="Please choose a brand"
            items={sortedBrands.map((brand) => {
              return {
                code: brand.code,
                value: brand.name.toUpperCase(),
              };
            })}
            onChangeSetValue={(event: SelectChangeEvent<string>): void => {
              dispatch(setSelectedBrandCode(event));
            }}
          />

          <Button
            className={styling.addButton}
            style={{ height: "37px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (createNewFileCard.SelectedBrandCode !== PLEASE_SELECT_BRAND_CODE) {
                dispatch(
                  addSelectedBrandCode({
                    name: sortedBrands.find((brand) => brand.code === createNewFileCard.SelectedBrandCode)?.name ?? "",
                    code: createNewFileCard.SelectedBrandCode,
                  })
                );
                dispatch(validateBranding());
              }
            }}
          >
            <AddIcon style={{ marginLeft: 10 }} />
          </Button>
        </div>
      ) : (
        <></>
      )}

      {[...(createNewFileCard.NewFileCard.Brands ? createNewFileCard.NewFileCard.Brands : [])]
        .sort((a: BrandType, b: BrandType): number => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map((brand) => (
          <BrandingBrand
            key={brand.code}
            name={brand.name}
            code={brand.code}
            control={props.control}
            errors={props.errors}
          />
        ))}
      {!createNewFileCard.IsBrandingValid && createNewFileCard.SendButtonClicked === true ? (
        <div style={{ marginTop: "10px" }}>
          <strong style={{ color: "red" }}>
            Brand setup is invalid, you should add at least one brand, and all brands should have at least one product
            line
          </strong>
        </div>
      ) : undefined}
    </div>
  );
};

export default Branding;
