import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MDDropDown from "../MDDropDown/MDDropDown";
import { RegionType } from "../../_types_/RegionType";
import { basicDataService } from "../../_services_/basicData.service";
import { AxiosResponse } from "axios";
import { LinearProgress } from "@mui/material";
import { isNullOrUndefined } from "../../utils/CommonUtils";

interface PropType {
  control: any;
  setValue: any;
  formState: any;
  trigger: any;
  label: string;
  regionName: string;
  error: any;
  value: number | undefined;
  countryValue: string | undefined;
  onChange: (value: any) => void;
}

const MDRegionDropDown = (props: PropType): ReactElement<PropType> => {
  const [t] = useTranslation("general");
  const [regions, setRegions] = useState<RegionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { regionName, value, formState, setValue, trigger, countryValue } = props;

  useEffect(() => {
    setValue(regionName, value);
  }, [regionName, value, setValue]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(regionName);
    }
  }, [regionName, formState.isSubmitted, trigger]);

  useEffect(() => {
    setLoading(true);
    if (!isNullOrUndefined(countryValue)) {
      basicDataService
        .getRegions(countryValue)
        .then((response: AxiosResponse<RegionType[]>): void => {
          setRegions(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setRegions([]);
    }
  }, [countryValue]);

  return loading ? (
    <LinearProgress />
  ) : regions.length > 0 ? (
    <MDDropDown
      name={regionName}
      error={props.error}
      control={props.control}
      label={props.label}
      onChangeSetValue={props.onChange}
      value={props.value ?? ""}
      items={regions.map((region) => {
        return {
          code: String(region.id),
          value: region.regionName,
        };
      })}
      validationText={t("validation.requiredField")}
    />
  ) : (
    <></>
  );
};

export default MDRegionDropDown;
