import { v4 as uuidv4 } from "uuid";
import { createSlice } from "@reduxjs/toolkit";
import { ALL_COMPANIES } from "../_constants_/constants";
import { FileCardAction } from "../_types_/actions/FileCardAction";
import { isNullOrUndefined } from "../utils/CommonUtils";

export const fileCardInitialState: FileCardAction = {
  fileCard: {
    id: 0,
    paymentTermAccepted: null,
    shipTos: [
      {
        shipToId: "0",
        deleted: false,
        deliveryAddress: {
          storeName: "",
          storeAddress: "",
          postalCode: "",
          city: "",
          countryISOAlpha3: "",
          contactPerson: "",
          storePhoneNumber: {
            callingCode: "",
            phoneNumber: "",
          },
          storeCellPhoneNumber: {
            callingCode: "",
            phoneNumber: "",
          },
          storeEmail: "",
          invoiceEmail: "",
          orderConfirmationEmail: "",
          webshopName: "",
          deliveryDate: null,
        },
        deliveryOverrideSwitchValue: false,
        deliveryOverride: {
          address: "",
          postalCode: "",
          city: "",
          countryISOAlpha3: "",
        },
      },
    ],
    billToAddress: {
      legalName: "",
      legalAddress: "",
      postalCode: "",
      city: "",
      countryISOAlpha3: "",
      vatNumber: null,
      kvkNumber: null,
      sirenNumber: null,
      contactPersonFunction: null,
      owner: "",
      accountsDepartmentPhoneNumber: { callingCode: "", phoneNumber: "" },
      statementOfAccountEmail: undefined,
      wholesaleAgreementEmail: "",
      smsCodeCellPhoneNumber: { callingCode: "", phoneNumber: "" },
      legalform: null,
      legalformText: null,
      districtCourt: null,
      companyRegistrationNumber: null,
    },
    logisticContact: null,
    adminUser: null,
    billing: null,
    guaranteeAmount: null,
    budget: null,
    paymentTermCode: null,
    guaranteeType: null,
    brands: undefined,
    status: 0,
    companyCode: 0,
    signer: null,
    contractType: null,
    budgetSplitPerBrand: null,
    documentLanguageCode: null,
    comments: null,
    dataProtectionCheck: false,
    businessTradingPartnerId: null,
  },
  allCountries: [
    {
      isoAlpha3: "",
      name: "",
      callingCode: "",
      deleted: false,
      euMember: false,
      internationalCode: "",
    },
  ],
  allTaxJurisdictions: [],
  allCompanies: ALL_COMPANIES,
  type: "",
  createAdminUser: false,
  selectedBrandCode: "",
};

const FileCardSlice = createSlice({
  name: "FileCardSlice",
  initialState: fileCardInitialState,
  reducers: {
    setFileCardId: (state, action) => {
      state.fileCard.id = action.payload;
    },

    setShipToStoreName: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.storeName = action.payload.storeName;
      }
    },

    setShipToStoreAddress: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.storeAddress = action.payload.storeAddress;
      }
    },

    setShipToAdditionalAddressInfo: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.additionalAddressInfo = action.payload.additionalAddressInfo;
      }
    },

    setShipToPostalCode: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.postalCode = action.payload.postalCode;
      }
    },

    setShipToCity: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.city = action.payload.city;
      }
    },

    setShipToCountry: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.countryISOAlpha3 = action.payload.countryISOAlpha3;
      }
    },

    setShipToDeliveryDate: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.deliveryDate = action.payload.deliveryDate;
      }
    },

    setShipToContactPerson: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.contactPerson = action.payload.contactPerson;
      }
    },

    setShipToTaxJurisdictionId: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.taxJurisdictionId = action.payload.taxJurisdictionId;
      }
    },

    setShipToRegionId: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.regionId = action.payload.regionId;
      }
    },

    setShipToStorePhoneNumber: (state, action) => {
      const { shipToId, phoneNumber } = action.payload;
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === shipToId);
      if (shipTo) {
        shipTo.deliveryAddress.storePhoneNumber.phoneNumber = phoneNumber;
      }
    },

    copyBillToAddressToShipTo: (state, action) => {
      const { shipToId } = action.payload;
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === shipToId);
      if (shipTo) {
        shipTo.deliveryAddress.storeAddress = state.fileCard.billToAddress.legalAddress;
        shipTo.deliveryAddress.postalCode = state.fileCard.billToAddress.postalCode;
        shipTo.deliveryAddress.city = state.fileCard.billToAddress.city;
        shipTo.deliveryAddress.countryISOAlpha3 = state.fileCard.billToAddress.countryISOAlpha3;
        shipTo.deliveryAddress.taxJurisdictionId = state.fileCard.billToAddress.taxJurisdictionId;
        shipTo.deliveryAddress.regionId = state.fileCard.billToAddress.regionId;
      }
    },

    setShipToStorePhoneNumberCallingCode: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);
      if (shipTo) {
        shipTo.deliveryAddress.storePhoneNumber.callingCode = action.payload.callingCode;
      }
    },

    setShipToStoreCellPhoneNumber: (state, action) => {
      const { shipToId, phoneNumber } = action.payload;
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === shipToId);
      if (shipTo) {
        shipTo.deliveryAddress.storeCellPhoneNumber.phoneNumber = phoneNumber;
      }
    },

    setShipToStoreCellPhoneNumberCallingCode: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.storeCellPhoneNumber.callingCode = action.payload.callingCode;
      }
    },

    setShipToStoreEmail: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.storeEmail = action.payload.storeEmail;
      }
    },

    setShipToInvoiceEmail: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.invoiceEmail = action.payload.invoiceEmail;
      }
    },

    setShipToOrderConfirmationEmail: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.orderConfirmationEmail = action.payload.orderConfirmationEmail;
      }
    },

    setShipToDirectPlatformEmail: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.directPlatformEmail = action.payload.directPlatformEmail;
      }
    },

    setShipToWebshopName: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryAddress.webshopName = action.payload.webshopName;
      }
    },

    setShipToDeliveryOverrideSwitchValue: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        shipTo.deliveryOverrideSwitchValue = action.payload.deliveryOverrideSwitchValue;
      }
    },

    setShipToDeliveryOverrideAddress: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        if (isNullOrUndefined(shipTo?.deliveryOverride)) {
          shipTo.deliveryOverride = {
            address: "",
            postalCode: "",
            city: "",
            countryISOAlpha3: "",
          };
        }
        shipTo.deliveryOverride.address = action.payload.address;
      }
    },

    setShipToDeliveryOverrideAdditionalAddressInfo: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        if (isNullOrUndefined(shipTo?.deliveryOverride)) {
          shipTo.deliveryOverride = {
            address: "",
            postalCode: "",
            city: "",
            countryISOAlpha3: "",
          };
        }

        shipTo.deliveryOverride.additionalAddressInfo = action.payload.additionalAddressInfo;
      }
    },

    setShipToDeliveryOverrideTaxJurisdictionId: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        if (isNullOrUndefined(shipTo?.deliveryOverride)) {
          shipTo.deliveryOverride = {
            address: "",
            postalCode: "",
            city: "",
            countryISOAlpha3: "",
          };
        }

        shipTo.deliveryOverride.taxJurisdictionId = action.payload.taxJurisdictionId;
      }
    },

    setShipToDeliveryOverridePostalCode: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        if (isNullOrUndefined(shipTo?.deliveryOverride)) {
          shipTo.deliveryOverride = {
            address: "",
            postalCode: "",
            city: "",
            countryISOAlpha3: "",
          };
        }

        shipTo.deliveryOverride.postalCode = action.payload.postalCode;
      }
    },

    setShipToDeliveryOverrideCity: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        if (isNullOrUndefined(shipTo?.deliveryOverride)) {
          shipTo.deliveryOverride = {
            address: "",
            postalCode: "",
            city: "",
            countryISOAlpha3: "",
          };
        }

        shipTo.deliveryOverride.city = action.payload.city;
      }
    },

    setShipToDeliveryOverrideCountryISOAlpha3: (state, action) => {
      const shipTo = state.fileCard?.shipTos.find((o) => o.shipToId === action.payload.shipToId);

      if (shipTo) {
        if (isNullOrUndefined(shipTo?.deliveryOverride)) {
          shipTo.deliveryOverride = {
            address: "",
            postalCode: "",
            city: "",
            countryISOAlpha3: "",
          };
        }

        shipTo.deliveryOverride.countryISOAlpha3 = action.payload.countryISOAlpha3;
      }
    },

    addShipTo: (state, action) => {
      const shipToId = action.payload === undefined ? uuidv4() : action.payload ?? uuidv4();
      let shipToItem = {
        shipToId: shipToId,
        deleted: false,
        deliveryAddress: {
          storeName: "",
          storeAddress: "",
          postalCode: "",
          city: "",
          countryISOAlpha3: "",
          contactPerson: "",
          storePhoneNumber: { callingCode: "", phoneNumber: "" },
          storeCellPhoneNumber: { callingCode: "", phoneNumber: "" },
          storeEmail: "",
          invoiceEmail: "",
          orderConfirmationEmail: "",
          webshopName: "",
          deliveryDate: null,
        },
        deliveryOverrideSwitchValue: false,
        deliveryOverride: {
          address: "",
          postalCode: "",
          city: "",
          countryISOAlpha3: "",
        },
      };

      state.fileCard.shipTos = state.fileCard.shipTos || [];

      if (isNullOrUndefined(state.fileCard.shipTos.find((o) => o.shipToId === shipToId))) {
        state.fileCard.shipTos.push(shipToItem);
      }
    },

    removeShipTo: (state, action) => {
      state.fileCard.shipTos = state.fileCard.shipTos.filter((o) => o.shipToId !== action.payload);
    },

    setBillToLegalName: (state, action) => {
      state.fileCard.billToAddress.legalName = action.payload;
    },

    setBillToLegalForm: (state, action) => {
      state.fileCard.billToAddress.legalform = action.payload;
    },

    setBillToLegalFormText: (state, action) => {
      state.fileCard.billToAddress.legalformText = action.payload;
    },

    setBillToDistrictCourt: (state, action) => {
      state.fileCard.billToAddress.districtCourt = action.payload;
    },

    setBillToCompanyRegistrationNumber: (state, action) => {
      state.fileCard.billToAddress.companyRegistrationNumber = action.payload;
    },

    setBillToLegalAddress: (state, action) => {
      state.fileCard.billToAddress.legalAddress = action.payload;
    },

    setBillToAdditionaAddressInfo: (state, action) => {
      state.fileCard.billToAddress.additionalAddressInfo = action.payload;
    },

    setBillToPostalCode: (state, action) => {
      state.fileCard.billToAddress.postalCode = action.payload;
    },

    setBillToCity: (state, action) => {
      state.fileCard.billToAddress.city = action.payload;
    },

    setBillToCountryISOAlpha3: (state, action) => {
      state.fileCard.billToAddress.countryISOAlpha3 = action.payload;
    },

    setBillToRegionId: (state, action) => {
      state.fileCard.billToAddress.regionId = action.payload;
    },

    setBillToVatNumber: (state, action) => {
      state.fileCard.billToAddress.vatNumber = action.payload;
    },

    setBillToKvkNumber: (state, action) => {
      state.fileCard.billToAddress.kvkNumber = action.payload;
    },

    setBillToSirenNumber: (state, action) => {
      state.fileCard.billToAddress.sirenNumber = action.payload;
    },

    setBillToContactPersonFunction: (state, action) => {
      state.fileCard.billToAddress.contactPersonFunction = action.payload;
    },

    setBillToOwner: (state, action) => {
      state.fileCard.billToAddress.owner = action.payload;
    },

    setBillToAccountsDepartmentPhoneNumber: (state, action) => {
      state.fileCard.billToAddress.accountsDepartmentPhoneNumber.phoneNumber = action.payload;
    },

    setBillToAccountsDepartmentPhoneNumberCallingCode: (state, action) => {
      state.fileCard.billToAddress.accountsDepartmentPhoneNumber.callingCode = action.payload;
    },

    setBillToStatementOfAccountEmail: (state, action) => {
      state.fileCard.billToAddress.statementOfAccountEmail = action.payload;
    },

    setBillToWholesaleAgreementEmail: (state, action) => {
      state.fileCard.billToAddress.wholesaleAgreementEmail = action.payload;
    },

    setBillToOrderConfirmationEmail: (state, action) => {
      state.fileCard.billToAddress.orderConfirmationEmail = action.payload;
    },

    setBillToDirectPlatformEmail: (state, action) => {
      state.fileCard.billToAddress.directPlatformEmail = action.payload;
    },

    setBillToSmsCodeCellPhoneNumber: (state, action) => {
      state.fileCard.billToAddress.smsCodeCellPhoneNumber.phoneNumber = action.payload;
    },

    setBillToSmsCodeCellPhoneNumberCallingCode: (state, action) => {
      state.fileCard.billToAddress.smsCodeCellPhoneNumber.callingCode = action.payload;
    },

    setBillToNoSingleReturn: (state, action) => {
      state.fileCard.billToAddress.noSingleReturn = action.payload;
    },

    setBillToTaxJurisdictionId: (state, action) => {
      state.fileCard.billToAddress.taxJurisdictionId = action.payload;
    },

    setBillToCompanyRegistrationNumberNotApplicable: (state, action) => {
      state.fileCard.billToAddress.companyRegistrationNumberNotApplicable = action.payload;
    },

    setBillToUseEdi: (state, action) => {
      state.fileCard.billToAddress.useEDI = action.payload;
    },

    setBillToHasVendorManual: (state, action) => {
      state.fileCard.billToAddress.hasVendorManual = action.payload;
    },

    setBillToShippingCompany: (state, action) => {
      state.fileCard.billToAddress.shippingCompany = action.payload;
    },

    setBillToParcelAccountNumber: (state, action) => {
      state.fileCard.billToAddress.parcelAccountNumber = action.payload;
    },

    SetBillToOVT: (state, action) => {
      state.fileCard.billToAddress.ovt = action.payload;
    },

    setBillToOperator: (state, action) => {
      state.fileCard.billToAddress.operator = action.payload;
    },

    setBillToRegisteredAsSalesTaxVendor: (state, action) => {
      state.fileCard.billToAddress.registeredAsSalesTaxVendor = action.payload;
    },

    setBillToCertificateOfAuthorityNumber: (state, action) => {
      state.fileCard.billToAddress.certificateOfAuthorityNumber = action.payload;
    },

    setBillToSalesTaxVendorExpiration: (state, action) => {
      state.fileCard.billToAddress.salesTaxVendorExpiration = action.payload;
    },

    setBillToOpenSince: (state, action) => {
      state.fileCard.billToAddress.openSince = action.payload;
    },

    setLogisticContactName: (state, action) => {
      if (isNullOrUndefined(state.fileCard.logisticContact)) {
        state.fileCard.logisticContact = { name: "", email: "", phoneNumber: { callingCode: "", phoneNumber: "" } };
      }

      if (state.fileCard.logisticContact) {
        state.fileCard.logisticContact.name = action.payload;
      }
    },

    setLogisticContactEmail: (state, action) => {
      if (isNullOrUndefined(state.fileCard.logisticContact)) {
        state.fileCard.logisticContact = { name: "", email: "", phoneNumber: { callingCode: "", phoneNumber: "" } };
      }

      if (state.fileCard.logisticContact) {
        state.fileCard.logisticContact.email = action.payload;
      }
    },

    setLogisticContactPhoneNumber: (state, action) => {
      if (isNullOrUndefined(state.fileCard.logisticContact)) {
        state.fileCard.logisticContact = { name: "", email: "", phoneNumber: { callingCode: "", phoneNumber: "" } };
      }

      if (state.fileCard.logisticContact) {
        state.fileCard.logisticContact.phoneNumber.phoneNumber = action.payload;
      }
    },

    setLogisticContactPhoneNumberCallingCode: (state, action) => {
      if (isNullOrUndefined(state.fileCard.logisticContact)) {
        state.fileCard.logisticContact = { name: "", email: "", phoneNumber: { callingCode: "", phoneNumber: "" } };
      }

      if (state.fileCard.logisticContact) {
        state.fileCard.logisticContact.phoneNumber.callingCode = action.payload;
      }
    },

    setAdminUserFirstName: (state, action) => {
      if (isNullOrUndefined(state.fileCard.adminUser)) {
        state.fileCard.adminUser = { firstName: "", lastName: "", email: "" };
      }

      if (state.fileCard.adminUser) {
        state.fileCard.adminUser.firstName = action.payload;
      }
    },

    setAdminUserLastName: (state, action) => {
      if (isNullOrUndefined(state.fileCard.adminUser)) {
        state.fileCard.adminUser = { firstName: "", lastName: "", email: "" };
      }

      if (state.fileCard.adminUser) {
        state.fileCard.adminUser.lastName = action.payload;
      }
    },

    setAdminUserEmail: (state, action) => {
      if (isNullOrUndefined(state.fileCard.adminUser)) {
        state.fileCard.adminUser = { firstName: "", lastName: "", email: "" };
      }

      if (state.fileCard.adminUser) {
        state.fileCard.adminUser.email = action.payload;
      }
    },

    setBillingLegalName: (state, action) => {
      if (!state.fileCard.billing) {
        state.fileCard.billing = {
          legalName: "",
          storeName: "",
          bankName: null,
          regNumber: null,
          accountNumber: null,
          ibanNumber: "",
          vatNumber: null,
          bic: null,
        };
      }

      if (state.fileCard.billing) {
        state.fileCard.billing.legalName = action.payload;
      }
    },

    setBillingStoreName: (state, action) => {
      if (!state.fileCard.billing) {
        state.fileCard.billing = {
          legalName: "",
          storeName: "",
          bankName: null,
          regNumber: null,
          accountNumber: null,
          ibanNumber: "",
          vatNumber: null,
          bic: null,
        };
      }

      if (state.fileCard.billing) {
        state.fileCard.billing.storeName = action.payload;
      }
    },

    setBillingBankName: (state, action) => {
      if (!state.fileCard.billing) {
        state.fileCard.billing = {
          legalName: "",
          storeName: "",
          bankName: null,
          regNumber: null,
          accountNumber: null,
          ibanNumber: "",
          vatNumber: null,
          bic: null,
        };
      }

      if (state.fileCard.billing) {
        state.fileCard.billing.bankName = action.payload;
      }
    },

    setBillingRegNumber: (state, action) => {
      if (!state.fileCard.billing) {
        state.fileCard.billing = {
          legalName: "",
          storeName: "",
          bankName: null,
          regNumber: null,
          accountNumber: null,
          ibanNumber: "",
          vatNumber: null,
          bic: null,
        };
      }

      if (state.fileCard.billing) {
        state.fileCard.billing.regNumber = action.payload;
      }
    },

    setBillingAccountNumber: (state, action) => {
      if (!state.fileCard.billing) {
        state.fileCard.billing = {
          legalName: "",
          storeName: "",
          bankName: null,
          regNumber: null,
          accountNumber: null,
          ibanNumber: "",
          vatNumber: null,
          bic: null,
        };
      }

      if (state.fileCard.billing) {
        state.fileCard.billing.accountNumber = action.payload;
      }
    },

    setBillingIbanNumber: (state, action) => {
      if (!state.fileCard.billing) {
        state.fileCard.billing = {
          legalName: "",
          storeName: "",
          bankName: null,
          regNumber: null,
          accountNumber: null,
          ibanNumber: "",
          vatNumber: null,
          bic: null,
        };
      }

      if (state.fileCard.billing) {
        state.fileCard.billing.ibanNumber = action.payload;
      }
    },

    setBillingVatNumber: (state, action) => {
      if (!state.fileCard.billing) {
        state.fileCard.billing = {
          legalName: "",
          storeName: "",
          bankName: null,
          regNumber: null,
          accountNumber: null,
          ibanNumber: "",
          vatNumber: null,
          bic: null,
        };
      }

      if (state.fileCard.billing) {
        state.fileCard.billing.vatNumber = action.payload;
      }
    },

    setBillingBic: (state, action) => {
      if (!state.fileCard.billing) {
        state.fileCard.billing = {
          legalName: "",
          storeName: "",
          bankName: null,
          regNumber: null,
          accountNumber: null,
          ibanNumber: "",
          vatNumber: null,
          bic: null,
        };
      }

      if (state.fileCard.billing) {
        state.fileCard.billing.bic = action.payload;
      }
    },

    setGuaranteeAmount: (state, action) => {
      state.fileCard.guaranteeAmount = action.payload;
    },

    setBudget: (state, action) => {
      state.fileCard.budget = action.payload;
    },

    setPaymentTermCode: (state, action) => {
      state.fileCard.paymentTermCode = action.payload;
    },

    setGuaranteeType: (state, action) => {
      state.fileCard.guaranteeType = action.payload;
    },

    setDocumentLanguageCode: (state, action) => {
      state.fileCard.documentLanguageCode = action.payload;
    },

    setComments: (state, action) => {
      state.fileCard.comments = action.payload;
    },

    setBusinessTradingPartnerId: (state, action) => {
      state.fileCard.businessTradingPartnerId = action.payload;
    },

    saveFileCardFromBackend: (state, action) => {
      state.fileCard = action.payload;
    },

    setCompanyCodeFromServer: (state, action) => {
      state.fileCard.companyCode = action.payload;
    },

    setAllCountries: (state, action) => {
      state.allCountries = action.payload;
    },

    setAllTaxJurisdictions: (state, action) => {
      state.allTaxJurisdictions = action.payload;
    },

    setBrands: (state, action) => {
      state.fileCard.brands = action.payload;
    },

    setSigner: (state, action) => {
      if (!state.fileCard.signer) {
        state.fileCard.signer = { employeeNumber: 0, phoneNumber: { callingCode: "", phoneNumber: "" } };
      }
      state.fileCard.signer.employeeNumber = action.payload.employeeNumber;
      state.fileCard.signer.phoneNumber = action.payload.phoneNumber;
    },

    setDataProtectionCheck: (state, action) => {
      state.fileCard.dataProtectionCheck = action.payload;
    },

    setContractType: (state, action) => {
      state.fileCard.contractType = action.payload;
    },

    setBudgetSplitPerBrand: (state, action) => {
      state.fileCard.budgetSplitPerBrand = action.payload;
    },

    setBudgetSplitPerBrandAmount: (state, action) => {
      const budgetSplitPerBrand = state.fileCard.budgetSplitPerBrand?.find(
        (b) => b.brandCode === action.payload.selectedBrandCode
      );

      if (budgetSplitPerBrand) {
        budgetSplitPerBrand.budgetAmount = action.payload.budget;
      }
    },
    addPaymentTermAccepted: (state, action) => {
      const paymentTermAcceptedItem = {
        id: -1,
        paymentTermCode: action.payload ? action.payload : "",
        accepted: false,
      };

      if (state.fileCard.paymentTermAccepted) {
        state.fileCard.paymentTermAccepted.push(paymentTermAcceptedItem);
      } else {
        state.fileCard.paymentTermAccepted = [paymentTermAcceptedItem];
      }
    },

    setPaymentTermAccepted: (state, action) => {
      const paymentTermAccepted = state.fileCard.paymentTermAccepted?.find(
        (p) => p.paymentTermCode === action.payload.paymentTermCode
      );

      if (paymentTermAccepted) {
        paymentTermAccepted.accepted = action.payload.accepted;
      }
    },
  },
});

export const {
  setFileCardId,
  setShipToStoreName,
  setShipToStoreAddress,
  setShipToAdditionalAddressInfo,
  setShipToPostalCode,
  setShipToCity,
  setShipToCountry,
  setShipToDeliveryDate,
  setShipToContactPerson,
  setShipToTaxJurisdictionId,
  setShipToRegionId,
  setShipToStorePhoneNumber,
  setShipToStorePhoneNumberCallingCode,
  setShipToStoreCellPhoneNumber,
  setShipToStoreCellPhoneNumberCallingCode,
  setShipToStoreEmail,
  setShipToInvoiceEmail,
  setShipToOrderConfirmationEmail,
  setShipToDirectPlatformEmail,
  setShipToWebshopName,
  setShipToDeliveryOverrideSwitchValue,
  setShipToDeliveryOverrideAddress,
  setShipToDeliveryOverrideAdditionalAddressInfo,
  setShipToDeliveryOverrideTaxJurisdictionId,
  setShipToDeliveryOverridePostalCode,
  setShipToDeliveryOverrideCity,
  setShipToDeliveryOverrideCountryISOAlpha3,
  addShipTo,
  removeShipTo,
  setBillToLegalName,
  setBillToLegalForm,
  setBillToLegalFormText,
  setBillToDistrictCourt,
  setBillToCompanyRegistrationNumber,
  setBillToLegalAddress,
  setBillToAdditionaAddressInfo,
  setBillToPostalCode,
  setBillToCity,
  setBillToCountryISOAlpha3,
  setBillToRegionId,
  setBillToVatNumber,
  setBillToKvkNumber,
  setBillToSirenNumber,
  setBillToContactPersonFunction,
  setBillToOwner,
  setBillToAccountsDepartmentPhoneNumber,
  setBillToAccountsDepartmentPhoneNumberCallingCode,
  setBillToStatementOfAccountEmail,
  setBillToWholesaleAgreementEmail,
  setBillToOrderConfirmationEmail,
  setBillToDirectPlatformEmail,
  setBillToSmsCodeCellPhoneNumber,
  setBillToSmsCodeCellPhoneNumberCallingCode,
  setBillToNoSingleReturn,
  setBillToTaxJurisdictionId,
  setBillToCompanyRegistrationNumberNotApplicable,
  setBillToUseEdi,
  setBillToHasVendorManual,
  setBillToShippingCompany,
  setBillToParcelAccountNumber,
  SetBillToOVT,
  setBillToOperator,
  setBillToRegisteredAsSalesTaxVendor,
  setBillToCertificateOfAuthorityNumber,
  setBillToSalesTaxVendorExpiration,
  setBillToOpenSince,
  setLogisticContactName,
  setLogisticContactEmail,
  setLogisticContactPhoneNumber,
  setLogisticContactPhoneNumberCallingCode,
  setAdminUserFirstName,
  setAdminUserLastName,
  setAdminUserEmail,
  setBillingLegalName,
  setBillingStoreName,
  setBillingBankName,
  setBillingRegNumber,
  setBillingAccountNumber,
  setBillingIbanNumber,
  setBillingVatNumber,
  setBillingBic,
  setGuaranteeAmount,
  setBudget,
  setPaymentTermCode,
  setGuaranteeType,
  setDocumentLanguageCode,
  setComments,
  setBusinessTradingPartnerId,
  saveFileCardFromBackend,
  setCompanyCodeFromServer,
  setAllCountries,
  setAllTaxJurisdictions,
  setBrands,
  setSigner,
  setDataProtectionCheck,
  setContractType,
  setBudgetSplitPerBrand,
  setBudgetSplitPerBrandAmount,
  addPaymentTermAccepted,
  setPaymentTermAccepted,
  copyBillToAddressToShipTo,
} = FileCardSlice.actions;

export default FileCardSlice.reducer;
