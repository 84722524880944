import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import { Controller } from "react-hook-form";
import classes from "./MDRadioButton.module.scss";
import ReactHtmlParser from "html-react-parser";

interface PropTypes {
  control: any;
  error: any;
  label: string;
  name: string;
  opt1Label: string;
  opt1Value: boolean | undefined | null;
  opt2Label: string;
  opt2Value: boolean | undefined | null;
  onChangeSetValue: (value: any) => void;
  validationText: string;
  valueToMatchCheckedWith?: boolean | null;
  setValue: any;
}

const MDRadioButton = (props: PropTypes): ReactElement<PropTypes> => {
  const { opt1Value, opt2Value, valueToMatchCheckedWith } = props;

  const { setValue, name } = props;

  useEffect(() => {
    setValue(name, valueToMatchCheckedWith);
  }, [valueToMatchCheckedWith, setValue, name]);

  useEffect(() => {}, [valueToMatchCheckedWith, opt2Value, opt1Value]);

  return (
    <>
      <Controller
        control={props.control}
        defaultValue={valueToMatchCheckedWith}
        render={({ field }) => (
          <FormControl fullWidth>
            <FormLabel id="headline" style={{ color: "black" }}>
              {ReactHtmlParser(props.label)}
            </FormLabel>
            <RadioGroup
              row
              onChange={(event) => {
                props.onChangeSetValue(event);
                field.onChange(event);
              }}
            >
              <FormControlLabel
                inputRef={field.ref}
                value={props.opt1Value}
                control={<Radio checked={opt1Value === valueToMatchCheckedWith} />}
                label={props.opt1Label}
              />
              <FormControlLabel
                value={props.opt2Value}
                control={<Radio checked={opt2Value === valueToMatchCheckedWith} />}
                label={props.opt2Label}
              />
            </RadioGroup>
          </FormControl>
        )}
        name={props.name}
        rules={{
          validate: (value: any) => {
            return value !== undefined && value !== null ? true : props.validationText;
          },
        }}
      />
      {props.error ? <div className={classes.validationText}>{props.validationText}</div> : undefined}
    </>
  );
};

export default MDRadioButton;
