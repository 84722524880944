import { properties } from "../properties";

export const getEnviroment = (): string => {
  if (
    properties.serviceApiURL?.includes("https://bestcardtest.bestseller.com")
  ) {
    return "TEST";
  } else return "DEV";
};

export const getSalestoolLink = (): string => {
  if (
    properties.serviceApiURL?.includes("https://bestcardtest.bestseller.com")
  ) {
    return "https://salestooltest.bestseller.com/";
  } else return "https://salestool.bestseller.com/";
};
