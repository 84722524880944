import { Clear } from "@mui/icons-material";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDCountryDropDown from "../../UI/MDCountryDropDown/MDCountryDropDown";
import MDEmailField from "../../UI/MDEmailField/MDEmailField";
import MDPhoneNumberField from "../../UI/MDPhoneNumberField/MDPhoneNumberField";
import MDPostalCodeCityField from "../../UI/MDPostalCodeCityField/MDPostalCodeCityField";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { isAdminUrl } from "../../utils/QueryStringUtil";
import { ALL_COMPANIES } from "../../_constants_/constants";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { ProspectType } from "../../_types_/ProspectType";
import { TaxJurisdictionType } from "../../_types_/serverTypes/TaxJurisdictionType";
import { ShipToType } from "../../_types_/ShipToType";
import TaxJurisdictions from "../BillToAddress/TaxJurisdictions";
import DeliveryDate from "../DeliveryDate/DeliveryDate";
import MDRegionDropDown from "../../UI/MDRegionDropDown/MDRegionDropDown";
import {
  removeShipTo,
  setShipToAdditionalAddressInfo,
  setShipToCity,
  setShipToContactPerson,
  setShipToCountry,
  setShipToDirectPlatformEmail,
  setShipToInvoiceEmail,
  setShipToOrderConfirmationEmail,
  setShipToPostalCode,
  setShipToRegionId,
  setShipToStoreAddress,
  setShipToStoreCellPhoneNumber,
  setShipToStoreCellPhoneNumberCallingCode,
  setShipToStoreEmail,
  setShipToStoreName,
  setShipToStorePhoneNumber,
  setShipToStorePhoneNumberCallingCode,
  setShipToTaxJurisdictionId,
  setShipToWebshopName,
  copyBillToAddressToShipTo,
} from "../../_reducers_/FileCardSlice";
import { Button } from "@mui/material";

interface PropType {
  defaultCountry: string;
  errors: any;
  control: any;
  trigger: any;
  formState: any;
  setValue: any;
  shipToId: any;
  prospectEmail: string;
  additionalAddressInfoEnabled: boolean;
  shipToIndex: number;
}
//deploy
const DeliveryAddress = (props: PropType): ReactElement<ProspectType> => {
  const dispatch = useDispatch();

  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);

  const [taxJurisdictionsEnabled, setTaxJurisdictionsEnabled] = useState(false);
  const [storePhoneNumberRequired, setStorePhoneNumberRequired] = useState(true);
  const [deliveryDateEnabled, setDeliveryDateEnabled] = useState(false);
  const [hideContactPerson, setHideContactPerson] = useState(false);
  const [hideStoreEmail, setHideStoreEmail] = useState(false);
  const [directPlatformEmailEnabled, setDirectPlatformEmailEnabled] = useState(false);
  const [regionsEnabled, setRegionsEnabled] = useState(false);
  const [copyBillToAddressButtonVisible, setCopyBillToAddressButtonVisible] = useState(false);

  const [postalCodeRegEx, setPostalCodeRegEx] = useState<string | null>();
  const [postalCodeFormat, setPostalCodeFormat] = useState<string | null>();
  const [storeCellPhoneNumberOptional, setStoreCellPhoneNumberOptional] = useState(false);
  const [hideStoreCellPhoneNumber, setHideStoreCellPhoneNumber] = useState(false);

  const [t] = useTranslation("general");

  const { errors, control, trigger, formState, setValue, shipToId, defaultCountry } = props;

  const shipTos = fileCard.fileCard.shipTos;

  const deliveryAddress = shipTos.find((o: ShipToType) => o.shipToId === shipToId)?.deliveryAddress;

  const countryISOAlpha3 = deliveryAddress?.countryISOAlpha3;

  useEffect(() => {
    if (!isAdminUrl()) {
      dispatch(setShipToCountry({ shipToId: shipToId, countryISOAlpha3: defaultCountry }));
    }
  }, [dispatch, defaultCountry, shipToId]);

  useEffect(() => {
    const setCallingCodeForStoreCellPhoneNumber = (callingCode: string | undefined) => {
      dispatch(setShipToStoreCellPhoneNumberCallingCode({ shipToId: shipToId, callingCode: callingCode }));
    };

    const setCallingCodeForStorePhoneNumber = (callingCode: string | undefined) => {
      dispatch(setShipToStorePhoneNumberCallingCode({ shipToId: shipToId, callingCode: callingCode }));
    };

    if (fileCard.allCountries !== null && countryISOAlpha3 !== null) {
      const country = fileCard.allCountries.find((c) => c.isoAlpha3 === countryISOAlpha3);

      setPostalCodeRegEx(country?.postalCodePattern);
      setPostalCodeFormat(country?.displayPostalCodePattern);

      if (!isAdminUrl()) {
        setCallingCodeForStorePhoneNumber(country?.callingCode);
        setCallingCodeForStoreCellPhoneNumber(country?.callingCode);
      }
    }
  }, [dispatch, fileCard.allCountries, countryISOAlpha3, shipToId]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger("storePostalCode");
    }
  }, [postalCodeRegEx, trigger, formState.isSubmitted]);

  useEffect(() => {
    const company = ALL_COMPANIES.find((c) => c.code === fileCard.fileCard.companyCode);

    if (company) {
      setStorePhoneNumberRequired(company.storePhoneNumberRequired);
      setDeliveryDateEnabled(company.deliveryDate);
      setHideContactPerson(company.hideContactPerson ? true : false);
      setHideStoreEmail(company.hideStoreEmail ? true : false);
      setTaxJurisdictionsEnabled(company.taxJurisdictions ? company.taxJurisdictions : false);
      setDirectPlatformEmailEnabled(company.directPlatformEmail ? true : false);
      setRegionsEnabled(company.regions ? true : false);
      setCopyBillToAddressButtonVisible(company.copyBillToAddressButton ? true : false);
      setStoreCellPhoneNumberOptional(company.storeCellPhoneNumberOptional ? true : false);
      setHideStoreCellPhoneNumber(company.storeCellPhoneNumberHidden ? true : false);
    }
  }, [fileCard.fileCard.companyCode]);

  useEffect(() => {
    setValue(`shipTo[${shipToId}].deliveryAddressCountryISOAlpha3`, countryISOAlpha3);
  }, [countryISOAlpha3, setValue, shipToId]);

  useEffect(() => {
    setValue(`shipTo[${shipToId}].storeAddress`, deliveryAddress?.storeAddress);
  }, [deliveryAddress?.storeAddress, setValue, shipToId]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(`shipTo[${shipToId}].deliveryAddressCountryISOAlpha3`);
    }
  }, [formState.isSubmitted, trigger, countryISOAlpha3, shipToId]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(`shipTo[${shipToId}].storeAddress`);
    }
  }, [formState.isSubmitted, trigger, deliveryAddress?.storeAddress, shipToId]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(`shipTo[${shipToId}].storePostalCode`);
    }
  }, [formState.isSubmitted, trigger, deliveryAddress?.postalCode, shipToId]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(`shipTo[${shipToId}].storeCity`);
    }
  }, [formState.isSubmitted, trigger, deliveryAddress?.city, shipToId]);

  useEffect(() => {
    if (formState.isSubmitted) {
      setValue(`shipTo[${shipToId}].taxJurisdiction`, deliveryAddress?.taxJurisdictionId);
      trigger(`shipTo[${shipToId}].taxJurisdiction`);
    }
  }, [formState.isSubmitted, trigger, deliveryAddress?.taxJurisdictionId, shipToId, setValue]);

  return (
    <div>
      {/* <h2>{t("deliveryAddress.headline")}</h2> */}

      <div className="textIconContainer">
        <h2 className="textItem">{t("deliveryAddress.headline")}</h2>

        {shipTos.length > 1 ? (
          <Clear
            className="iconItem"
            onClick={() => {
              dispatch(removeShipTo(shipToId));
            }}
          />
        ) : null}
      </div>

      <MDTextField
        control={control}
        setValue={setValue}
        label={t("deliveryAddress.storeName")}
        name={`shipTo[${shipToId}].storeName`}
        error={errors.shipTo ? errors.shipTo[shipToId]?.storeName : undefined}
        value={deliveryAddress ? deliveryAddress?.storeName : ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setShipToStoreName({ shipToId: shipToId, storeName: e }));
        }}
        rules={{
          required: { message: t("validation.requiredField"), value: true },
        }}
      />
      {copyBillToAddressButtonVisible && !isAdminUrl() && props.shipToIndex === 0 ? (
        <Button
          variant="contained"
          color="primary"
          style={{
            width: "290px",
            marginBottom: "17px",
          }}
          onClick={() => {
            dispatch(copyBillToAddressToShipTo({ shipToId: shipToId }));
          }}
        >
          {t("deliveryAddress.copyBillToAddressButton")}
        </Button>
      ) : undefined}

      <MDTextField
        control={control}
        label={t("deliveryAddress.storeAddress")}
        name={`shipTo[${shipToId}].storeAddress`}
        error={errors.shipTo ? errors.shipTo[shipToId]?.storeAddress : undefined}
        value={deliveryAddress ? deliveryAddress?.storeAddress : ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setShipToStoreAddress({ shipToId: shipToId, storeAddress: e }));
        }}
        rules={{
          required: { message: t("validation.requiredField"), value: true },
        }}
      />
      {props.additionalAddressInfoEnabled ? (
        <MDTextField
          control={control}
          label={t("deliveryAddress.additionalAddressInfo")}
          name={`shipTo[${shipToId}].additionalAddressInfo`}
          error={errors.shipTo ? errors.shipTo[shipToId]?.additionalAddressInfo : undefined}
          value={deliveryAddress ? deliveryAddress?.additionalAddressInfo : ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setShipToAdditionalAddressInfo({ shipToId: shipToId, additionalAddressInfo: e }));
          }}
          rules={{
            maxLength: {
              message: t("validation.maxNumberDigits", { number: 100 }),
              value: 100,
            },
          }}
        />
      ) : undefined}

      <MDPostalCodeCityField
        control={control}
        setValue={setValue}
        postalCodeLabel={t("shared.postal-code")}
        postalCodeName={`shipTo[${shipToId}].storePostalCode`}
        postalCodeError={errors.shipTo ? errors.shipTo[shipToId]?.storePostalCode : undefined}
        postalCodeValue={deliveryAddress ? deliveryAddress?.postalCode : ""}
        postalCodeOnChange={(e: string): void => {
          dispatch(setShipToPostalCode({ shipToId: shipToId, postalCode: e }));
        }}
        postalCodeRegexPattern={postalCodeRegEx}
        postalCodeDisplayFormat={postalCodeFormat}
        cityLabel={t("shared.city")}
        cityName={`shipTo[${shipToId}].storeCity`}
        cityError={errors.shipTo ? errors.shipTo[shipToId]?.storeCity : undefined}
        cityValue={deliveryAddress ? deliveryAddress?.city : ""}
        cityOnChange={(e: string): void => {
          dispatch(setShipToCity({ shipToId: shipToId, city: e }));
        }}
      />

      <MDCountryDropDown
        control={control}
        setValue={setValue}
        trigger={trigger}
        formState={formState}
        defaultCountry={defaultCountry}
        countryLabel={t("shared.country")}
        countryName={`shipTo[${shipToId}].deliveryAddressCountryISOAlpha3`}
        countryError={errors.shipTo ? errors.shipTo[shipToId]?.deliveryAddressCountryISOAlpha3 : undefined}
        countryValue={deliveryAddress ? countryISOAlpha3 : ""}
        countryOnChange={(e: string): void => {
          dispatch(setShipToCountry({ shipToId: shipToId, countryISOAlpha3: e }));
        }}
      />

      {regionsEnabled ? (
        <MDRegionDropDown
          control={control}
          setValue={setValue}
          trigger={trigger}
          formState={formState}
          countryValue={
            deliveryAddress ? (deliveryAddress.countryISOAlpha3 ? deliveryAddress.countryISOAlpha3 : "") : ""
          }
          error={errors.billToRegion}
          regionName={`shipTo[${shipToId}].region`}
          label={t("shared.region")}
          value={deliveryAddress?.regionId}
          onChange={(event: number): void => {
            dispatch(setShipToRegionId({ shipToId: shipToId, regionId: event ? event : null }));
          }}
        />
      ) : undefined}

      {taxJurisdictionsEnabled ? (
        <TaxJurisdictions
          error={errors.shipTo ? errors.shipTo[shipToId]?.taxJurisdiction : undefined}
          control={control}
          setValue={setValue}
          name={`shipTo[${shipToId}].taxJurisdiction`}
          value={deliveryAddress?.taxJurisdictionId ?? 0}
          onChangeSetValue={(e: TaxJurisdictionType): void => {
            dispatch(setShipToTaxJurisdictionId({ shipToId: shipToId, taxJurisdictionId: e ? e.id : null }));
          }}
        />
      ) : undefined}

      {deliveryDateEnabled ? <DeliveryDate control={control} errors={errors} shipToId={shipToId} /> : undefined}

      {hideContactPerson ? undefined : (
        <MDTextField
          control={control}
          label={t("deliveryAddress.contactPerson")}
          name={`shipTo[${shipToId}].contactPerson`}
          error={errors.shipTo ? errors.shipTo[shipToId]?.contactPerson : undefined}
          value={deliveryAddress ? deliveryAddress?.contactPerson : ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setShipToContactPerson({ shipToId: shipToId, contactPerson: e }));
          }}
          rules={{
            required: { message: t("validation.requiredField"), value: true },
          }}
        />
      )}

      <MDPhoneNumberField
        control={control}
        setValue={setValue}
        trigger={trigger}
        formState={formState}
        phoneNumberRequired={storePhoneNumberRequired}
        callingCodeName={`shipTo[${shipToId}].storePhoneNumberCode`}
        callingCodeError={errors.shipTo ? errors.shipTo[shipToId]?.storePhoneNumberCode : undefined}
        callingCodeValue={deliveryAddress?.storePhoneNumber?.callingCode ?? ""}
        callingCodeOnChange={(e: string): void => {
          dispatch(setShipToStorePhoneNumberCallingCode({ shipToId: shipToId, callingCode: e }));
        }}
        phoneNumberLabel={t("deliveryAddress.storePhoneNumber")}
        phoneNumberName={`shipTo[${shipToId}].storePhoneNumber`}
        phoneNumberError={errors.shipTo ? errors.shipTo[shipToId]?.storePhoneNumber : undefined}
        phoneNumberValue={deliveryAddress?.storePhoneNumber?.phoneNumber ?? ""}
        phoneNumberOnChange={(e: string): void => {
          dispatch(setShipToStorePhoneNumber({ shipToId: shipToId, phoneNumber: e }));
        }}
      />

      {hideStoreCellPhoneNumber ? undefined : (
        <MDPhoneNumberField
          control={control}
          setValue={setValue}
          trigger={trigger}
          formState={formState}
          phoneNumberRequired={storeCellPhoneNumberOptional ? false : true}
          callingCodeName={`shipTo[${shipToId}].storeCellPhoneNumberCallingCode`}
          callingCodeError={errors.shipTo ? errors.shipTo[shipToId]?.storeCellPhoneNumberCallingCode : undefined}
          callingCodeValue={deliveryAddress?.storeCellPhoneNumber?.callingCode ?? ""}
          callingCodeOnChange={(e: string): void => {
            dispatch(setShipToStoreCellPhoneNumberCallingCode({ shipToId: shipToId, callingCode: e }));
          }}
          phoneNumberLabel={t("deliveryAddress.storeCellPhoneNumber")}
          phoneNumberName={`shipTo[${shipToId}].storeCellPhoneNumber`}
          phoneNumberError={errors.shipTo ? errors.shipTo[shipToId]?.storeCellPhoneNumber : undefined}
          phoneNumberValue={deliveryAddress?.storeCellPhoneNumber?.phoneNumber ?? ""}
          phoneNumberOnChange={(e: string): void => {
            dispatch(setShipToStoreCellPhoneNumber({ shipToId: shipToId, phoneNumber: e }));
          }}
        />
      )}

      {hideStoreEmail ? undefined : (
        <MDEmailField
          control={control}
          setValue={setValue}
          emailLabel={t("deliveryAddress.storeEmail")}
          emailName={`shipTo[${shipToId}].storeEmail`}
          emailError={errors.shipTo ? errors.shipTo[shipToId]?.storeEmail : undefined}
          emailValue={deliveryAddress?.storeEmail}
          emailOnChange={(e: string): void => {
            dispatch(setShipToStoreEmail({ shipToId: shipToId, storeEmail: e }));
          }}
        />
      )}

      <MDEmailField
        control={control}
        setValue={setValue}
        emailLabel={t("deliveryAddress.invoiceEmail")}
        emailName={`shipTo[${shipToId}].invoiceEmail`}
        emailError={errors.shipTo ? errors.shipTo[shipToId]?.invoiceEmail : undefined}
        emailValue={deliveryAddress?.invoiceEmail}
        emailOnChange={(e: string): void => {
          dispatch(setShipToInvoiceEmail({ shipToId: shipToId, invoiceEmail: e }));
        }}
      />

      <MDEmailField
        control={control}
        setValue={setValue}
        emailLabel={t("deliveryAddress.orderConfirmationEmail")}
        emailName={`shipTo[${shipToId}].orderConfirmationEmail`}
        emailError={errors.shipTo ? errors.shipTo[shipToId]?.orderConfirmationEmail : undefined}
        emailValue={deliveryAddress?.orderConfirmationEmail}
        emailOnChange={(e: string): void => {
          dispatch(setShipToOrderConfirmationEmail({ shipToId: shipToId, orderConfirmationEmail: e }));
        }}
      />

      {directPlatformEmailEnabled ? (
        <MDEmailField
          control={control}
          setValue={setValue}
          emailLabel={t("deliveryAddress.directPlatformEmail")}
          emailName="deliveryAddressDirectPlatformEmail"
          emailError={errors.deliveryAddressDirectPlatformEmail}
          emailValue={deliveryAddress ? deliveryAddress.directPlatformEmail : ""}
          emailOnChange={(e: string): void => {
            dispatch(setShipToDirectPlatformEmail({ shipToId: shipToId, directPlatformEmail: e }));
          }}
        />
      ) : undefined}

      <MDTextField
        control={control}
        label={t("deliveryAddress.webshopName")}
        name={`shipTo[${shipToId}].webshopName`}
        error={errors.shipTo ? errors.shipTo[shipToId]?.webshopName : undefined}
        value={deliveryAddress ? deliveryAddress.webshopName : ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setShipToWebshopName({ shipToId: shipToId, webshopName: e }));
        }}
      />
    </div>
  );
};

export default DeliveryAddress;
