import React, { ReactElement, useEffect, useState } from "react";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FileCardAction } from "../../_types_/actions/FileCardAction";

interface PropType {
  companyCode: number;
}

const HeaderText = (props: PropType): ReactElement<PropType> => {
  const [t_c] = useTranslation(props.companyCode.toString());
  const [t] = useTranslation("general");
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);

  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    const findSelectedCompanyName = fileCard.allCompanies.find(
      (company) => company.code === fileCard.fileCard.companyCode
    );
    setCompanyName(findSelectedCompanyName ? findSelectedCompanyName.name : "");
  }, [fileCard]);

  return (
    <div>
      <h1>{ReactHtmlParser(t("header.headline", { companyName: companyName }))}</h1>
      {ReactHtmlParser(t_c("html.header-text"))}
    </div>
  );
};

export default HeaderText;
