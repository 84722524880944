import axios, { AxiosPromise } from "axios";
import { properties } from "../properties";
import { FileCardFromServer } from "../_types_/actions/FileCardAction";
import { FileCardType } from "../_types_/FileCardType";
import { CreateNewFileCardType } from "../_types_/CreateNewFileCardType";
import { FileCardServerType } from "../_types_/serverTypes/FileCardServerType";
import { SignerServerType } from "../_types_/serverTypes/SignerServerType";

const requestOptions = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const sendFileCard = (
  accessToken: string,
  fileCard: FileCardType
): AxiosPromise<FileCardType> => {
  return axios({
    url: properties.serviceApiURL + "FileCard/" + accessToken,
    method: "post",
    data: JSON.stringify(fileCard),
    headers: requestOptions.headers,
  });
};

const sendAdminFileCard = (
  accessToken: string,
  fileCard: FileCardType
): AxiosPromise<FileCardType> => {
  return axios({
    url: properties.serviceApiURL + "admin/filecard/" + accessToken,
    method: "post",
    data: JSON.stringify(fileCard),
    headers: requestOptions.headers,
    withCredentials: true,
  });
};

const sendAdminApproveFileCard = (
  accessToken: string
): AxiosPromise<FileCardType> => {
  return axios({
    url:
      properties.serviceApiURL + "admin/filecard/" + accessToken + "/approve",
    method: "put",
    headers: requestOptions.headers,
    withCredentials: true,
  });
};

const getFileCard = (accessToken: string): AxiosPromise<FileCardFromServer> => {
  return axios({
    url: properties.serviceApiURL + "FileCard/" + accessToken,
    method: "get",
    headers: requestOptions.headers,
  });
};

const getAdminFileCard = (
  fileCardId: string
): AxiosPromise<FileCardFromServer> => {
  return axios({
    url: properties.serviceApiURL + "admin/filecard/" + fileCardId,
    method: "get",
    headers: requestOptions.headers,
    withCredentials: true,
  });
};

const getFrenchFileCards = (): AxiosPromise<FileCardServerType[]> => {
  return axios({
    url:
      properties.serviceApiURL +
      "admin/filecards?companyCodes=253&companyCodes=270",
    method: "get",
    headers: requestOptions.headers,
    withCredentials: true,
  });
};

const getSigners = (companyCode: number): AxiosPromise<SignerServerType[]> => {
  return axios({
    url: properties.serviceApiURL + "admin/signers/" + companyCode,
    method: "get",
    headers: requestOptions.headers,
    withCredentials: true,
  });
};

const createNewFileCard = (
  newFileCard: CreateNewFileCardType
): AxiosPromise<CreateNewFileCardType> => {
  return axios({
    url: properties.serviceApiURL + "FileCard",
    method: "post",
    data: JSON.stringify(newFileCard),
    headers: requestOptions.headers,
    withCredentials: true,
  });
};

export const fileCardService = {
  sendFileCard,
  getFileCard,
  createNewFileCard,
  getAdminFileCard,
  sendAdminFileCard,
  sendAdminApproveFileCard,
  getSigners,
  getFrenchFileCards,
};
