import React, { ChangeEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDRadioButton from "../../UI/MDRadioButton/MDRadioButton";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { setBillToNoSingleReturn } from "../../_reducers_/FileCardSlice";

interface PropType {
  companyCode: number;
  errors: any;
  control: any;
  setValue: any;
}

const NoSingleReturn = (props: PropType): ReactElement<PropType> => {
  const { t } = useTranslation("general");
  const [t_cc] = useTranslation(props.companyCode.toString());

  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const dispatch = useDispatch();

  return (
    <div>
      <h2>{t("noSingleReturn.headline")}</h2>
      <p>{t_cc("noSingleReturn.description")}</p>
      <MDRadioButton
        control={props.control}
        name="noSingleReturn"
        error={props.errors.noSingleReturn}
        setValue={props.setValue}
        label=""
        opt1Label={t("noSingleReturn.optionYes")}
        opt2Label={t("noSingleReturn.optionNo")}
        validationText={t("noSingleReturn.selectionRequired")}
        opt1Value={true}
        opt2Value={false}
        onChangeSetValue={(event: ChangeEvent<HTMLInputElement>): void => {
          dispatch(setBillToNoSingleReturn((event.target as HTMLInputElement).value === "true"));
        }}
        valueToMatchCheckedWith={fileCard.fileCard.billToAddress.noSingleReturn}
      />
    </div>
  );
};

export default NoSingleReturn;
