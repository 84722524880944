import { Button, Dialog, LinearProgress } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { basicDataService } from "../_services_/basicData.service";
import { fileCardService } from "../_services_/fileCard.service";
import { CreateNewFileCardAction } from "../_types_/actions/CreateNewFileCardAction";
import { CompanyServerType } from "../_types_/serverTypes/CompanyServerType";
import Branding from "./Branding/Branding";
import ChooseCompany from "./ChooseCompany/ChooseCompany";

import MDTextField from "../UI/MDTextField/MDTextField";
import { isIE } from "../utils/IsIE";
import InternetExplorerNotAllowed from "../UI/InternetExplorerNotAllowed/InternetExplorerNotAllowed";
import {
  setAllProductLines,
  setSendButtonClicked,
  setAllBrands,
  setCustomerName,
  setCustomerEmail,
} from "../_reducers_/CreateNewFileCardSlice";

const REQUIRED_FIELD_TEXT = "This field is required";

const CreateNewFileCard = (): ReactElement => {
  const createNewFileCard = useSelector(
    (state: { createNewFileCard: CreateNewFileCardAction }) => state.createNewFileCard
  );
  const dispatch = useDispatch();
  const [fileCardCreated, setFileCardCreated] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const sendNewFileCard = (data: Record<string, any>): void => {
    dispatch(setSendButtonClicked());
    if (!createNewFileCard.IsBrandingValid) {
      return;
    } else {
      setLoading(true);
      fileCardService
        .createNewFileCard(createNewFileCard.NewFileCard)
        .then(() => {
          setFileCardCreated(true);
          setLoading(false);
        })
        .catch((error: AxiosError): void => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    const findSelectedCompanyName = createNewFileCard.BasicMasterData.AllCompanies.find(
      (company) => company.code === createNewFileCard.NewFileCard.CompanyCode
    );
    setSelectedCompanyName(findSelectedCompanyName ? findSelectedCompanyName.name : "");
  }, [createNewFileCard]);

  useEffect(() => {
    if (createNewFileCard.NewFileCard.CompanyCode !== 0) {
      setLoading(true);
      basicDataService
        .getCompanyWithBrands(String(createNewFileCard.NewFileCard.CompanyCode))
        .then((response: AxiosResponse<CompanyServerType>): void => {
          const transformedData = response.data.brands.map((s) => ({
            code: s.code,
            productLines: s.productLines,
          }));

          dispatch(setAllProductLines(transformedData));
          dispatch(setAllBrands(response.data.brands));

          setLoading(false);
        })
        .finally((): void => {
          setLoading(false);
        });
    }
  }, [dispatch, createNewFileCard.NewFileCard.CompanyCode]);

  return isIE() ? (
    <InternetExplorerNotAllowed />
  ) : error ? (
    <p>Error creating filecard</p>
  ) : fileCardCreated ? (
    <p>
      File card has been created, an e-mail has been sent to{" "}
      <strong>{createNewFileCard.NewFileCard.Prospect.email}</strong>
    </p>
  ) : !createNewFileCard.IsCompanySelected ? (
    <ChooseCompany errors={errors} control={control} />
  ) : (
    <>
      <form onSubmit={handleSubmit(sendNewFileCard)}>
        <div>
          <h1>Create new file card</h1>

          <MDTextField
            label="Company"
            name="company"
            value={selectedCompanyName}
            onChangeSetValue={() => {}}
            error={errors.company}
            disabled={true}
            control={control}
          />

          <MDTextField
            name="customerName"
            label="Customer Name"
            value={createNewFileCard.NewFileCard.Prospect.name}
            error={errors.customerName}
            control={control}
            onChangeSetValue={(e: string): void => {
              dispatch(setCustomerName(e));
            }}
            rules={{
              required: { message: REQUIRED_FIELD_TEXT, value: true },
            }}
          />

          <MDTextField
            label="Customer E-mail (file card will be sent to this email)"
            name="customerEmail"
            value={createNewFileCard.NewFileCard.Prospect.email}
            error={errors.customerEmail}
            control={control}
            onChangeSetValue={(e: string): void => {
              dispatch(setCustomerEmail(e));
            }}
            rules={{
              required: { value: true, message: "This field is required" },
              validate: (value: string) => {
                const re =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(value).toLowerCase()) ? true : "Please enter a valid e-mail address";
              },
            }}
          />
        </div>

        <Branding errors={errors} control={control} />
        <br />
        <Button variant="contained" color="primary" type="submit" className="sendButton">
          Send
        </Button>
      </form>

      <Dialog open={loading}>
        <LinearProgress style={{ width: "200px" }} />
      </Dialog>
    </>
  );
};

export default CreateNewFileCard;
