import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { ONLY_NUMBERS_VALIDATION_REGEX } from "../../_constants_/constants";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { setBudgetSplitPerBrandAmount } from "../../_reducers_/FileCardSlice";

interface PropType {
  errors: any;
  control: any;
}

const BudgetSplitPerBrand = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const dispatch = useDispatch();
  const { t } = useTranslation("general");
  const [budgetSplitPerBrand, setBudgetSplitPerBrand] = useState(fileCard.fileCard.budgetSplitPerBrand);

  useEffect(() => {
    if (fileCard.fileCard.budgetSplitPerBrand) {
      setBudgetSplitPerBrand(fileCard.fileCard.budgetSplitPerBrand);
    }
  }, [fileCard.fileCard.budgetSplitPerBrand]);

  const calculateTotalBudget = (): string => {
    var totalBudget = 0;
    budgetSplitPerBrand?.forEach((brand) => {
      if (brand.budgetAmount != null && !isNaN(brand.budgetAmount)) {
        totalBudget += brand.budgetAmount;
      }
    });

    return String(totalBudget);
  };

  return (
    <div>
      {budgetSplitPerBrand?.map((bspb, i) => (
        <MDTextField
          label={t("budget.budgetAmountPerBrand", {
            brandName: fileCard.fileCard.brands?.find((b) => b.code === bspb.brandCode)?.name.toUpperCase(),
          })}
          name={`fieldArray[${i}].budgetAmount`}
          error={props.errors.fieldArray ? props.errors.fieldArray[i]?.budgetAmount : undefined}
          control={props.control}
          key={i}
          rules={{
            min: {
              message: t("validation.minNumberDigits", {
                number: 0,
              }),
              value: 0,
            },
            max: {
              message: t("validation.maxNumberDigits", {
                number: 2147483647,
              }),
              value: 2147483647,
            },
            pattern: {
              value: ONLY_NUMBERS_VALIDATION_REGEX,
              message: t("validation.canOnlyHoldNumbers"),
            },
          }}
          value={String(
            budgetSplitPerBrand
              ? budgetSplitPerBrand?.find((bs) => bs.brandCode === bspb.brandCode)?.budgetAmount === null
                ? ""
                : budgetSplitPerBrand?.find((bs) => bs.brandCode === bspb.brandCode)?.budgetAmount
              : ""
          )}
          onChangeSetValue={(e: string): void => {
            dispatch(
              setBudgetSplitPerBrandAmount({
                budget: e === "" ? null : isNaN(Number(e)) ? e : Number(e),
                selectedBrandCode: bspb.brandCode,
              })
            );
          }}
        />
      ))}
      {budgetSplitPerBrand ? (
        budgetSplitPerBrand.length > 1 ? (
          <MDTextField
            label={t("budget.totalBudget")}
            disabled={true}
            name="totalBudget"
            error={props.errors.totalBudget}
            control={props.control}
            value={calculateTotalBudget()}
            onChangeSetValue={(e: string): void => {}}
          />
        ) : undefined
      ) : undefined}
    </div>
  );
};

export default BudgetSplitPerBrand;
