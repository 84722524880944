import React, { ReactElement } from "react";
import { getEnviroment } from "../../../utils/Enviroment";
import boxClass from "./EnvironmentBox.module.scss";
import LaunchIcon from "@mui/icons-material/Launch";

const EnvironmentBox = (): ReactElement => {
  const TEST_URL = "welcometest.bestseller.com";
  const LOCALHOST_URL = "localhost:3000";

  const replaceUrl = (from: string, to: string) => {
    const currentUrl = window.location.href;
    if (currentUrl.indexOf(from) > -1) {
      window.location.href = currentUrl.replace(from, to);
    }
  };

  const urlIncludes = (text: string): boolean => {
    const currentUrl = window.location.href;
    return currentUrl.indexOf(text) > -1;
  };

  return (
    <div className={boxClass.main__menu}>
      <div className={boxClass.listItem}>
        <div className={boxClass.a + " " + boxClass.topMenu}>
          <span>Environment: {getEnviroment()}</span>
        </div>
        <ul className={boxClass.dropMenu + " " + boxClass.menu2 + "ul"}>
          <li className={boxClass.dropItem}>
            <div
              onClick={() => {
                if (urlIncludes(LOCALHOST_URL)) {
                  replaceUrl(LOCALHOST_URL, TEST_URL);
                } else if (urlIncludes(TEST_URL)) {
                  replaceUrl(TEST_URL, LOCALHOST_URL);
                }
              }}
              className={boxClass.a + " " + boxClass.item2}
            >
              <table>
                <tbody>
                  <tr>
                    <td>
                      <LaunchIcon className={boxClass.launchIcon} />
                    </td>
                    <td>
                      {urlIncludes(LOCALHOST_URL)
                        ? "Test"
                        : urlIncludes(TEST_URL)
                        ? "Localhost"
                        : undefined}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EnvironmentBox;
