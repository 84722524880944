import { makeStyles } from '@mui/styles';

export const usePaperStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "left",
    color: "black",
    boxShadow: "none",
    borderRadius: 0
  },
  noShadow: {
    boxShadow: "none",
    borderRadius: 0
  },
  TextValidator: {
    width: "100%",
    textAlign: "left"
  },
  PostalCode: {
    width: "29%",
    paddingRight: "1%"
  },
  City: {
    width: "70%"
  },
  Tabel: {
    overflowX: "auto",
    width: "100%"
  }
}));
