import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDSwitchButton from "../../UI/MDSwitchButton/MDSwitchButton";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { setBillToCompanyRegistrationNumberNotApplicable } from "../../_reducers_/FileCardSlice";

interface PropType {
  control: any;
  errors: any;
  setCompanyRegistrationNumberEnabled: any;
}

const CompanyRegNumberNotApplicable = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const dispatch = useDispatch();
  const [t] = useTranslation("general");
  const switchLabel = t("billToAddress.companyRegistrationNumberNotApplicable");

  const { control, errors, setCompanyRegistrationNumberEnabled } = props;

  const billToAddress = fileCard.fileCard.billToAddress;

  useEffect(() => {
    if (billToAddress.companyRegistrationNumberNotApplicable) {
      setCompanyRegistrationNumberEnabled(!billToAddress.companyRegistrationNumberNotApplicable);
    }
  }, [billToAddress.companyRegistrationNumberNotApplicable, setCompanyRegistrationNumberEnabled]);

  return (
    <>
      <MDSwitchButton
        control={control}
        label={switchLabel}
        name="companyRegistrationNumberNotApplicable"
        error={errors.companyRegistrationNumberNotApplicable}
        value={billToAddress.companyRegistrationNumberNotApplicable}
        onChangeSetValue={(checked: boolean) => {
          dispatch(setBillToCompanyRegistrationNumberNotApplicable(checked));
          setCompanyRegistrationNumberEnabled(!checked);
        }}
      />
    </>
  );
};

export default CompanyRegNumberNotApplicable;
