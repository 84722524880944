import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDCountryDropDown from "../../UI/MDCountryDropDown/MDCountryDropDown";
import MDPostalCodeCityField from "../../UI/MDPostalCodeCityField/MDPostalCodeCityField";
import MDSwitchButton from "../../UI/MDSwitchButton/MDSwitchButton";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { isNullOrUndefined } from "../../utils/CommonUtils";
import { ALL_COMPANIES } from "../../_constants_/constants";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { TaxJurisdictionType } from "../../_types_/serverTypes/TaxJurisdictionType";
import { ShipToType } from "../../_types_/ShipToType";
import TaxJurisdictions from "../BillToAddress/TaxJurisdictions";
import {
  setShipToDeliveryOverrideAdditionalAddressInfo,
  setShipToDeliveryOverrideAddress,
  setShipToDeliveryOverrideCity,
  setShipToDeliveryOverrideCountryISOAlpha3,
  setShipToDeliveryOverridePostalCode,
  setShipToDeliveryOverrideSwitchValue,
  setShipToDeliveryOverrideTaxJurisdictionId,
} from "../../_reducers_/FileCardSlice";

interface PropType {
  defaultCountry: string;
  errors: any;
  control: any;
  trigger: any;
  formState: any;
  setValue: any;
  shipToId: any;
  additionalAddressInfoEnabled: boolean;
}

const DeliveryOverride = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);

  const { t } = useTranslation("general");
  const dispatch = useDispatch();

  const translationLabel = t("deliveryOverride.switch");

  const [postalCodeRegEx, setPostalCodeRegEx] = useState<string | null>();
  const [postalCodeFormat, setPostalCodeFormat] = useState<string | null>();
  const [taxJurisdictionsEnabled, setTaxJurisdictionsEnabled] = useState(false);

  const { control, errors, trigger, formState, setValue, shipToId, defaultCountry } = props;

  const allCountries = fileCard.allCountries;

  const shipTo = fileCard.fileCard.shipTos.find((o: ShipToType) => o.shipToId === shipToId);

  const deliveryOverrideSwitchValue = shipTo?.deliveryOverrideSwitchValue;

  const deliveryOverride = shipTo?.deliveryOverride;
  const countryISOAlpha3 = deliveryOverride?.countryISOAlpha3;

  useEffect(() => {
    if (allCountries !== null && countryISOAlpha3 !== null) {
      const country = allCountries.find((c) => c.isoAlpha3 === countryISOAlpha3);

      setPostalCodeRegEx(country?.postalCodePattern);
      setPostalCodeFormat(country?.displayPostalCodePattern);
    }
  }, [allCountries, countryISOAlpha3, dispatch]);

  useEffect(() => {
    setValue(`shipTo[${shipToId}].deliveryOverrideCountryISOAlpha3`, countryISOAlpha3);
  }, [countryISOAlpha3, setValue, shipToId]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(`shipTo[${shipToId}].deliveryOverrideCountryISOAlpha3`);
    }
  }, [countryISOAlpha3, shipToId, formState.isSubmitted, trigger]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(`shipTo[${shipToId}].deliveryOverridePostalCode`);
    }
  }, [postalCodeRegEx, trigger, formState.isSubmitted, shipToId]);

  useEffect(() => {
    if (deliveryOverrideSwitchValue && isNullOrUndefined(deliveryOverride)) {
      dispatch(setShipToDeliveryOverrideCountryISOAlpha3({ shipToId: shipToId, countryISOAlpha3: defaultCountry }));
    }
  }, [dispatch, deliveryOverride, deliveryOverrideSwitchValue, shipToId, defaultCountry]);

  useEffect(() => {
    const company = ALL_COMPANIES.find((c) => c.code === fileCard.fileCard.companyCode);

    if (company) {
      setTaxJurisdictionsEnabled(company.taxJurisdictions ? company.taxJurisdictions : false);
    }
  }, [fileCard.fileCard.companyCode]);

  return (
    <div>
      {" "}
      <MDSwitchButton
        control={control}
        label={translationLabel}
        name="deliveryOverrideSwitchValue"
        error={errors.deliveryOverrideSwitchValue}
        value={deliveryOverrideSwitchValue}
        onChangeSetValue={(checked: boolean) => {
          if (checked && countryISOAlpha3 === "") {
            dispatch(
              setShipToDeliveryOverrideCountryISOAlpha3({ shipToId: shipToId, countryISOAlpha3: defaultCountry })
            );
          }

          dispatch(setShipToDeliveryOverrideSwitchValue({ shipToId: shipToId, deliveryOverrideSwitchValue: checked }));
        }}
      />
      {shipTo?.deliveryOverrideSwitchValue ? (
        <>
          {" "}
          <MDTextField
            control={control}
            label={t("deliveryOverride.address")}
            name={`shipTo[${shipToId}].deliveryOverrideAddress`}
            error={errors.shipTo ? errors.shipTo[shipToId]?.deliveryOverrideAddress : undefined}
            value={deliveryOverride?.address ?? ""}
            onChangeSetValue={(e: string): void => {
              dispatch(setShipToDeliveryOverrideAddress({ shipToId: shipToId, address: e }));
            }}
            rules={{
              required: { message: t("validation.requiredField"), value: true },
            }}
          />
          {props.additionalAddressInfoEnabled ? (
            <MDTextField
              control={control}
              label={t("deliveryOverride.additionalAddressInfo")}
              name={`shipTo[${shipToId}].additionalAddressInfo`}
              error={errors.shipTo ? errors.shipTo[shipToId]?.additionalAddressInfo : undefined}
              value={deliveryOverride?.additionalAddressInfo ?? ""}
              onChangeSetValue={(e: string): void => {
                dispatch(
                  setShipToDeliveryOverrideAdditionalAddressInfo({ shipToId: shipToId, additionalAddressInfo: e })
                );
              }}
              rules={{
                maxLength: {
                  message: t("validation.maxNumberDigits", { number: 100 }),
                  value: 100,
                },
              }}
            />
          ) : undefined}
          <MDPostalCodeCityField
            control={control}
            setValue={setValue}
            postalCodeLabel={t("deliveryOverride.postalCode")}
            postalCodeName={`shipTo[${shipToId}].deliveryOverridePostalCode`}
            postalCodeError={errors.shipTo ? errors.shipTo[shipToId]?.deliveryOverridePostalCode : undefined}
            postalCodeValue={deliveryOverride ? deliveryOverride?.postalCode : ""}
            postalCodeOnChange={(e: string): void => {
              dispatch(setShipToDeliveryOverridePostalCode({ shipToId: shipToId, postalCode: e }));
            }}
            postalCodeRegexPattern={postalCodeRegEx}
            postalCodeDisplayFormat={postalCodeFormat}
            cityLabel={t("deliveryOverride.city")}
            cityName={`shipTo[${shipToId}].deliveryOverrideCity`}
            cityError={errors.shipTo ? errors.shipTo[shipToId]?.deliveryOverrideCity : undefined}
            cityValue={deliveryOverride?.city ?? ""}
            cityOnChange={(e: string): void => {
              dispatch(setShipToDeliveryOverrideCity({ shipToId: shipToId, city: e }));
            }}
          />
          <MDCountryDropDown
            control={control}
            setValue={setValue}
            trigger={trigger}
            formState={formState}
            defaultCountry={defaultCountry}
            countryLabel={t("deliveryOverride.country")}
            countryName={`shipTo[${shipToId}].deliveryOverrideCountryISOAlpha3`}
            countryError={errors.shipTo ? errors.shipTo[shipToId]?.deliveryOverrideCountryISOAlpha3 : undefined}
            countryValue={deliveryOverride?.countryISOAlpha3 ?? ""}
            countryOnChange={(e: string): void => {
              dispatch(setShipToDeliveryOverrideCountryISOAlpha3({ shipToId: shipToId, countryISOAlpha3: e }));
            }}
          />
          {taxJurisdictionsEnabled ? (
            <TaxJurisdictions
              error={errors.shipTo ? errors.shipTo[shipToId]?.deliveryOverrideTaxJurisdiction : undefined}
              control={control}
              setValue={setValue}
              name={`shipTo[${shipToId}].deliveryOverrideTaxJurisdiction`}
              value={deliveryOverride?.taxJurisdictionId ?? 0}
              onChangeSetValue={(e: TaxJurisdictionType): void => {
                dispatch(
                  setShipToDeliveryOverrideTaxJurisdictionId({ shipToId: shipToId, taxJurisdictionId: e ? e.id : null })
                );
              }}
            />
          ) : undefined}
        </>
      ) : undefined}
    </div>
  );
};

export default DeliveryOverride;
