/* eslint-disable jsx-a11y/anchor-is-valid */
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal, Paper } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDCheckBox from "../../UI/MDCheckBox/MDCheckBox";
import { getTokenFromUrl } from "../../utils/QueryStringUtil";
import { ALL_COMPANIES } from "../../_constants_/constants";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { setDataProtectionCheck } from "../../_reducers_/FileCardSlice";
import { RegionToVersionType } from "../../_types_/RegionToVersionType";
import { isNullOrUndefined } from "../../utils/CommonUtils";

interface PropTypes {
  errors: any;
  control: any;
}

const DataProtectionCheckmarks = (props: PropTypes): ReactElement<PropTypes> => {
  const dispatch = useDispatch();
  const { t } = useTranslation("general");
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const [language, setLanguage] = useState<string | null>();

  const [deliveryTermDownloadLinkEnabled, setDeliveryTermDownloadLinkEnabled] = useState(false);

  const [freightChargeCheckbox, setFreightChargeCheckbox] = useState(false);
  const [noReturnDamageCheckbox, setNoReturnDamageCheckbox] = useState(false);
  const [singlePaymentTermAcceptCheckBox, setSinglePaymentTermAcceptCheckBox] = useState(false);
  const [creditCardFeeCheckbox, setCreditCardFeeCheckbox] = useState(false);
  const [regionToDeliveryTermVersion, setRegionToDeliveryTermVersion] = useState<RegionToVersionType[] | null>(null);

  useEffect(() => {
    const company = ALL_COMPANIES.find((c) => c.code === fileCard.fileCard.companyCode);
    setDeliveryTermDownloadLinkEnabled(company?.deliveryTermDownloadLink ? company.deliveryTermDownloadLink : false);
    setFreightChargeCheckbox(company?.freightChargeCheckbox ? company.freightChargeCheckbox : false);
    setNoReturnDamageCheckbox(company?.noReturnDamageCheckbox ? company.noReturnDamageCheckbox : false);
    setSinglePaymentTermAcceptCheckBox(
      company?.singlePaymentTermAcceptCheckBox ? company.singlePaymentTermAcceptCheckBox : false
    );
    setCreditCardFeeCheckbox(company?.creditCardFeeCheckbox ? company.creditCardFeeCheckbox : false);
    setRegionToDeliveryTermVersion(company?.regionToDeliveryTermVersion ? company.regionToDeliveryTermVersion : null);
  }, [dispatch, fileCard.fileCard.companyCode]);

  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    dispatch(setDataProtectionCheck(event.target.checked));
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get("language");
    setLanguage(language);
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [marketing, setmarketing] = useState(false);
  const [deliveryTermsLinkCheck, setDeliveryTermsLinkCheck] = useState(false);
  const [freightChargeCheckboxCheck, setFreightChargeCheckboxCheck] = useState(false);
  const [noReturnDamageCheckboxCheck, setNoReturnDamageCheckboxCheck] = useState(false);
  const [singlePaymentTermAcceptCheckBoxCheck, setSinglePaymentTermAcceptCheckBoxCheck] = useState(false);
  const [creditCardFeeCheckboxCheck, setCreditCardFeeCheckboxCheck] = useState(false);

  const getLanguageString = (): string => {
    if (language === null) {
      return "";
    } else {
      return "language=" + language;
    }
  };

  const getFreightCheckboxLabel = () => {
    if (isNullOrUndefined(regionToDeliveryTermVersion)) {
      return t("mandatoryCheckMarks.freightChargeCheckbox");
    }

    let ontarioOrQuebec = false;
    let otherRegion = false;

    for (let i = 0; i < fileCard.fileCard.shipTos.length; i++) {
      const currentShipTo = fileCard.fileCard.shipTos[i];
      const regionId = currentShipTo.deliveryAddress.regionId;
      const isVersionOne = regionToDeliveryTermVersion?.findIndex(
        (region) => region.regionId === Number(regionId) && region.version === "1"
      );
      if (isVersionOne !== -1) {
        ontarioOrQuebec = true;
      } else {
        otherRegion = true;
      }
    }

    if (ontarioOrQuebec && otherRegion) {
      return t("mandatoryCheckMarks.freightChargeCheckbox");
    }
    if (ontarioOrQuebec) {
      return t("mandatoryCheckMarks.freightChargeCheckboxOntarioQuebec");
    }
    return t("mandatoryCheckMarks.freightChargeCheckboxOtherRegion");
  };

  const isRegionChoosenForAShipTo = () => {
    let result = false;
    for (let i = 0; i < fileCard.fileCard.shipTos.length; i++) {
      const currentShipTo = fileCard.fileCard.shipTos[i];
      if (!isNullOrUndefined(currentShipTo.deliveryAddress.regionId)) {
        result = true;
      }
    }
    return result;
  };

  const getVersion = (): string => {
    let ontarioOrQuebec = false;
    let otherRegion = false;

    for (let i = 0; i < fileCard.fileCard.shipTos.length; i++) {
      const currentShipTo = fileCard.fileCard.shipTos[i];
      const regionId = currentShipTo.deliveryAddress.regionId;

      const isVersionOne = regionToDeliveryTermVersion?.findIndex(
        (region) => region.regionId === Number(regionId) && region.version === "1"
      );

      if (isVersionOne !== -1) {
        ontarioOrQuebec = true;
      } else {
        otherRegion = true;
      }
    }

    if (ontarioOrQuebec && otherRegion) {
      return "3";
    }
    if (ontarioOrQuebec) {
      return "1";
    }
    return "2";
  };

  const getVersionString = (): string => {
    if (isNullOrUndefined(regionToDeliveryTermVersion)) {
      return "";
    }
    return "v=" + getVersion();
  };

  const getDeliveryTermsParams = () => {
    const language = getLanguageString();
    const version = getVersionString();
    if (language === "" && version === "") {
      return "";
    } else if (language === "") {
      return "?" + version;
    } else {
      return "?" + language + "&" + version;
    }
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "50px 0px 30px 30px",
            width: "70%",
            maxWidth: "1000px",
            boxShadow: "24px",
            height: "90%",
            display: "block",
          }}
        >
          <IconButton style={{ position: "absolute", right: "0px", top: "0px" }} onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
          <div
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: "100%",
              paddingRight: "30px",
            }}
          >
            {ReactHtmlParser(t("dataProtection.privacyPolicy"))}
          </div>
        </Paper>
      </Modal>
      <MDCheckBox
        name={"dataProtectionCheckmark"}
        value={fileCard.fileCard.dataProtectionCheck ? true : false}
        error={props.errors.dataProtectionCheckmark}
        control={props.control}
        onChangeSetValue={(event) => {
          handleChange(event);
        }}
        vAlignLabelTop={false}
        label={
          <>
            {t("dataProtection.checkmarkLabel.beforeLink")}
            <a
              href="#"
              onClick={() => {
                handleOpen();
              }}
            >
              {t("dataProtection.checkmarkLabel.linkTitle")}
            </a>{" "}
            {t("dataProtection.checkmarkLabel.afterLink")}
          </>
        }
      />
      <MDCheckBox
        name={"marketingCheckmark"}
        value={marketing === undefined ? null : marketing}
        error={props.errors.marketingCheckmark}
        control={props.control}
        onChangeSetValue={(event) => {
          setmarketing(event.target.checked);
        }}
        vAlignLabelTop={true}
        label={
          <>
            {t("dataProtection.markingLabel.beforeLink")}
            {t("dataProtection.markingLabel.linkTitle") !== "dataProtection.markingLabel.linkTitle" ? (
              <a
                href="#"
                onClick={() => {
                  handleOpen();
                }}
              >
                {t("dataProtection.markingLabel.linkTitle")}
              </a>
            ) : undefined}{" "}
            {t("dataProtection.markingLabel.afterLink")}
          </>
        }
      />
      {deliveryTermDownloadLinkEnabled &&
      (regionToDeliveryTermVersion === null ||
        (regionToDeliveryTermVersion !== null && isRegionChoosenForAShipTo())) ? (
        <MDCheckBox
          name={"deliveryTermsLinkCheckmark"}
          value={deliveryTermsLinkCheck === undefined ? null : deliveryTermsLinkCheck}
          error={props.errors.deliveryTermsLinkCheckmark}
          control={props.control}
          onChangeSetValue={(event) => {
            setDeliveryTermsLinkCheck(event.target.checked);
          }}
          vAlignLabelTop={true}
          label={
            <>
              {t("deliveryTermsLink.label.beforeLink")}
              {t("deliveryTermsLink.label.linkTitle") !== "deliveryTermsLink.label.linkTitle" ? (
                <a
                  href="#"
                  onClick={() => {
                    window.open(
                      process.env.REACT_APP_API_URL +
                        "filecard/" +
                        getTokenFromUrl() +
                        "/SalesAndDeliveryTerms" +
                        getDeliveryTermsParams(),
                      "_blank"
                    );
                  }}
                >
                  {t("deliveryTermsLink.label.linkTitle")}
                </a>
              ) : undefined}{" "}
              {t("deliveryTermsLink.label.afterLink")}
            </>
          }
        />
      ) : undefined}
      {freightChargeCheckbox &&
      (regionToDeliveryTermVersion === null ||
        (regionToDeliveryTermVersion !== null && isRegionChoosenForAShipTo())) ? (
        <MDCheckBox
          name={"freightChargeCheckbox"}
          value={freightChargeCheckboxCheck === undefined ? null : freightChargeCheckboxCheck}
          error={props.errors.freightChargeCheckbox}
          control={props.control}
          onChangeSetValue={(event) => {
            setFreightChargeCheckboxCheck(event.target.checked);
          }}
          vAlignLabelTop={true}
          label={<>{getFreightCheckboxLabel()}</>}
        />
      ) : undefined}
      {noReturnDamageCheckbox ? (
        <MDCheckBox
          name={"noReturnDamageCheckbox"}
          value={noReturnDamageCheckboxCheck === undefined ? null : noReturnDamageCheckboxCheck}
          error={props.errors.noReturnDamageCheckbox}
          control={props.control}
          onChangeSetValue={(event) => {
            setNoReturnDamageCheckboxCheck(event.target.checked);
          }}
          vAlignLabelTop={true}
          label={<>{t("mandatoryCheckMarks.noReturnDamageCheckbox")}</>}
        />
      ) : undefined}
      {singlePaymentTermAcceptCheckBox ? (
        <MDCheckBox
          name={"singlePaymentTermAcceptCheckBox"}
          value={singlePaymentTermAcceptCheckBoxCheck === undefined ? null : singlePaymentTermAcceptCheckBoxCheck}
          error={props.errors.singlePaymentTermAcceptCheckBox}
          control={props.control}
          onChangeSetValue={(event) => {
            setSinglePaymentTermAcceptCheckBoxCheck(event.target.checked);
          }}
          vAlignLabelTop={true}
          label={<>{t("mandatoryCheckMarks.singlePaymentTermAcceptCheckBox")}</>}
        />
      ) : undefined}
      {creditCardFeeCheckbox ? (
        <MDCheckBox
          name={"creditCardFeeCheckbox"}
          value={creditCardFeeCheckboxCheck === undefined ? null : creditCardFeeCheckboxCheck}
          error={props.errors.creditCardFeeCheckbox}
          control={props.control}
          onChangeSetValue={(event) => {
            setCreditCardFeeCheckboxCheck(event.target.checked);
          }}
          vAlignLabelTop={true}
          label={<>{t("mandatoryCheckMarks.creditCardFeeCheckbox")}</>}
        />
      ) : undefined}
    </div>
  );
};
export default DataProtectionCheckmarks;
