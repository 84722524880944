import React, { ReactElement } from "react";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from 'react-i18next';

interface PropType {
  companyCode: number;
}

const PaymentTermDescription = (props: PropType): ReactElement<PropType> => {
  const [t] = useTranslation(props.companyCode.toString());
  return (
    <div style={{ color: "black" }}>
      {ReactHtmlParser(t('html.payment-term-text'))}
    </div>
  );
};

export default PaymentTermDescription;
