import { createTheme, StyledEngineProvider, Theme, ThemeProvider } from "@mui/material/styles";
import i18next from "i18next";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import da_483 from "./Translations/da/483_da.json";
import da_484 from "./Translations/da/484_da.json";
import general_da from "./Translations/da/general_da.json";
import de_AT_212 from "./Translations/de-AT/212_de-AT.json";
import de_AT_265 from "./Translations/de-AT/265_de-AT.json";
import general_de_AT from "./Translations/de-AT/general_de-AT.json";

import de_CH_205 from "./Translations/de-CH/205_de-CH.json";
import de_CH_264 from "./Translations/de-CH/264_de-CH.json";
import general_de_CH from "./Translations/de-CH/general_de-CH.json";

import de_245 from "./Translations/de/245_de.json";
import de_246 from "./Translations/de/246_de.json";
import general_de from "./Translations/de/general_de.json";
import en_AT_212 from "./Translations/en-AT/212_en-AT.json";
import en_AT_265 from "./Translations/en-AT/265_en-AT.json";
import general_en_AT from "./Translations/en-AT/general_en-AT.json";
import en_CH_205 from "./Translations/en-CH/205_en-CH.json";
import en_CH_264 from "./Translations/en-CH/264_en-CH.json";
import general_en_CH from "./Translations/en-CH/general_en-CH.json";
import en_256 from "./Translations/en/256_en.json";
import en_267 from "./Translations/en/267_en.json";
import en_483 from "./Translations/en/483_en.json";
import general_en from "./Translations/en/general_en.json";
import fr_250 from "./Translations/fr/250_fr.json";
import fr_253 from "./Translations/fr/253_fr.json";
import fr_262 from "./Translations/fr/262_fr.json";
import fr_270 from "./Translations/fr/270_fr.json";
import general_fr from "./Translations/fr/general_fr.json";
import nl_250 from "./Translations/nl/250_nl.json";
import nl_251 from "./Translations/nl/251_nl.json";
import nl_262 from "./Translations/nl/262_nl.json";
import nl_263 from "./Translations/nl/263_nl.json";
import general_nl from "./Translations/nl/general_nl.json";
import no_206 from "./Translations/no/206_no.json";
import no_273 from "./Translations/no/273_no.json";
import general_no from "./Translations/no/general_no.json";
import se_242 from "./Translations/se/242_se.json";
import se_278 from "./Translations/se/278_se.json";
import general_se from "./Translations/se/general_se.json";
import es_255 from "./Translations/es/255_es.json";
import es_276 from "./Translations/es/276_es.json";
import general_es from "./Translations/es/general_es.json";
import pt_255 from "./Translations/pt/255_pt.json";
import pt_276 from "./Translations/pt/276_pt.json";
import general_pt from "./Translations/pt/general_pt.json";
import en_us_302 from "./Translations/en-US/302_en-us.json";
import general_en_us from "./Translations/en-US/general_en-us.json";
import en_fi_254 from "./Translations/en-FI/254_en-fi.json";
import en_fi_269 from "./Translations/en-FI/269_en-fi.json";
import general_en_fi from "./Translations/en-FI/general_en-fi.json";
import fi_254 from "./Translations/fi/254_fi.json";
import fi_269 from "./Translations/fi/269_fi.json";
import general_fi from "./Translations/fi/general_fi.json";

import en_tr_208 from "./Translations/en-TR/208_en-TR.json";
import general_en_tr from "./Translations/en-TR/general_en-TR.json";
import tr_208 from "./Translations/tr/208_tr.json";
import general_tr from "./Translations/tr/general_tr.json";

import en_ca_252 from "./Translations/en-CA/252_en-ca.json";
import general_en_ca from "./Translations/en-CA/general_en-ca.json";
import fr_ca_252 from "./Translations/fr-CA/252_fr-ca.json";
import general_fr_ca from "./Translations/fr-CA/general_fr-ca.json";

import general_it from "./Translations/it/general_it.json";
import it_214 from "./Translations/it/214_it.json";
import it_516 from "./Translations/it/516_it.json";

import en_me_589 from "./Translations/en-ME/589_en-me.json";
import general_en_me from "./Translations/en-ME/general_en-me.json";

import BottomBar from "./UI/BottomBar/BottomBar";
import TopBar from "./UI/TopBar/TopBar";
import Store from "./_store_/Store";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

//import common_en from "./translations/en/common.json";

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: "span",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: `AdelleBSOffice`,
          // fontSize: "16px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { fontFamily: `AdelleBSOffice` },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: `AdelleBSOffice`,
          backgroundColor: "white",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { boxShadow: "none", borderRadius: 0 },
        contained: { boxShadow: "none" },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: { marginBottom: "17px" },
      },
    },
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: { fontSize: "15px", paddin: "0px" },
    //   },
    // },
  },
  palette: {
    background: {
      default: "#E0E0E0",
    },

    primary: {
      main: "#757575",
    },

    secondary: {
      main: "#3D3A3B",
    },
  },
  typography: {
    fontFamily: `AdelleBSOffice`,
    // fontSize: 16,
    h6: { fontFamily: `AdelleBSOffice`, color: "black" },
    body1: {
      fontFamily: `AdelleBSOffice`,
      fontSize: 16,
    },
  },
});

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "da", // language to use
  resources: {
    da: {
      general: general_da,
      483: da_483,
      484: da_484,
    },
    nl: {
      general: general_nl,
      251: nl_251,
      263: nl_263,
      250: nl_250,
      262: nl_262,
    },
    se: {
      general: general_se,
      242: se_242,
      278: se_278,
    },
    fr: {
      general: general_fr,
      270: fr_270,
      253: fr_253,
      250: fr_250,
      262: fr_262,
    },
    de: {
      general: general_de,
      245: de_245,
      246: de_246,
    },
    en: {
      general: general_en,
      483: en_483,
      256: en_256,
      267: en_267,
    },
    no: {
      general: general_no,
      206: no_206,
      273: no_273,
    },
    en_at: {
      general: general_en_AT,
      212: en_AT_212,
      265: en_AT_265,
    },
    de_at: {
      general: general_de_AT,
      212: de_AT_212,
      265: de_AT_265,
    },
    de_ch: {
      general: general_de_CH,
      205: de_CH_205,
      264: de_CH_264,
    },
    en_ch: {
      general: general_en_CH,
      205: en_CH_205,
      264: en_CH_264,
    },
    es: {
      general: general_es,
      255: es_255,
      276: es_276,
    },
    pt: {
      general: general_pt,
      255: pt_255,
      276: pt_276,
    },
    en_us: {
      general: general_en_us,
      302: en_us_302,
    },
    en_fi: {
      general: general_en_fi,
      269: en_fi_269,
      254: en_fi_254,
    },
    fi: {
      general: general_fi,
      269: fi_269,
      254: fi_254,
    },
    tr: {
      general: general_tr,
      208: tr_208,
    },
    en_tr: {
      general: general_en_tr,
      208: en_tr_208,
    },
    en_ca: {
      general: general_en_ca,
      252: en_ca_252,
    },
    fr_ca: {
      general: general_fr_ca,
      252: fr_ca_252,
    },
    it: {
      general: general_it,
      214: it_214,
      516: it_516,
    },
    en_me: {
      general: general_en_me,
      589: en_me_589,
    },
  },
});

ReactDOM.render(
  <Provider store={Store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <TopBar />
        <div className="min-margin-container">
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </div>
        <BottomBar />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
