/* eslint-disable jsx-a11y/anchor-is-valid */
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface PropTypes {
  name: string;
  value: any;
  error: any;
  control: any;
  vAlignLabelTop: boolean;
  label: any;
  onChangeSetValue: (value: any) => void;
  optional?: boolean;
  disabled?: boolean;
}

const MDCheckBox = (props: PropTypes): ReactElement<PropTypes> => {
  const { t } = useTranslation("general");

  const dataProtectionCheckValidate = (value: boolean) => {
    if (value === null) return false;
    return value;
  };

  return (
    <>
      <Controller
        control={props.control}
        render={({ field }) => (
          <FormControl fullWidth disabled={props.disabled ? true : false}>
            <FormControlLabel
              style={props.vAlignLabelTop ? { display: "table" } : undefined}
              control={
                <div style={props.vAlignLabelTop ? { display: "table-cell" } : undefined}>
                  <Checkbox
                    sx={
                      props.error
                        ? {
                            color: "#d32f2f",
                          }
                        : undefined
                    }
                    inputRef={field.ref}
                    checked={props.value ? props.value : false}
                    onChange={(event) => {
                      props.onChangeSetValue(event);
                      field.onChange(event.target.checked);
                    }}
                  />
                </div>
              }
              label={props.label}
            />
          </FormControl>
        )}
        name={props.name}
        rules={props.optional ? undefined : { validate: dataProtectionCheckValidate }}
        defaultValue={props.value ? props.value : false}
      />
      {props.error ? (
        <div
          style={{
            color: "red",
            marginTop: props.vAlignLabelTop ? "-10px" : "-20px",
            paddingLeft: "35px",
            paddingBottom: "10px",
          }}
        >
          {t("validation.acceptRequired")}
        </div>
      ) : undefined}
    </>
  );
};
export default MDCheckBox;
