import axios, { AxiosPromise } from "axios";
import { properties } from "../properties";
import { CompanyType } from "../_types_/CompanyType";
import { CountryType } from "../_types_/CountryType";
import { ProductLineType } from "../_types_/ProductLineType";
import { CompanyServerType } from "../_types_/serverTypes/CompanyServerType";
import { TaxJurisdictionType } from "../_types_/serverTypes/TaxJurisdictionType";
import { RegionType } from "../_types_/RegionType";

const requestOptions = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

const getCompanyWithBrands = (companyCode: string): AxiosPromise<CompanyServerType> => {
  return axios({
    url: properties.serviceApiURL + "companies/" + companyCode + "/brands",
    method: "get",
    headers: requestOptions.headers,
  });
};

const getSalesPersonsForProductLine = (productLineCode: string, companyCode: string): AxiosPromise<ProductLineType> => {
  return axios({
    url: properties.serviceApiURL + "productlines/" + productLineCode + "?companyCode=" + companyCode,
    method: "get",
    headers: requestOptions.headers,
  });
};

const getCompaniesForUser = (): AxiosPromise<CompanyType[]> => {
  return axios({
    url: properties.serviceApiURL + "admin/companies",
    method: "get",
    headers: requestOptions.headers,
    withCredentials: true,
  });
};

const getAllCountries = (): AxiosPromise<CountryType[]> => {
  return axios({
    url: properties.serviceApiURL + "countries",
    method: "get",
    headers: requestOptions.headers,
  });
};

const getTaxJurisdictions = (isoAlpha3: string): AxiosPromise<TaxJurisdictionType[]> => {
  return axios({
    url: properties.serviceApiURL + "TaxJurisdictions/" + isoAlpha3,
    method: "get",
    headers: requestOptions.headers,
  });
};

const getRegions = (isoAlpha3: string): AxiosPromise<RegionType[]> => {
  return axios({
    url: properties.serviceApiURL + "countries/regions/" + isoAlpha3,
    method: "get",
    headers: requestOptions.headers,
  });
};


export const basicDataService = {
  getCompanyWithBrands,
  getSalesPersonsForProductLine,
  getAllCountries,
  getCompaniesForUser,
  getTaxJurisdictions,
  getRegions,
};
