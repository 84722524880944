import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { SigningAction } from "../_types_/actions/SigningAction";

export const signingInitialState: SigningAction = {
  docuSignEnvelope: null,
};

const SigningSlice = createSlice({
  name: "SigningSlice",
  initialState: signingInitialState,
  reducers: {
    setDocuSignEnvelope: (state, action: PayloadAction<SigningAction>) => {
      state.docuSignEnvelope = action.payload.docuSignEnvelope;
    },
    setRecipientName: (state, action) => {
      const reciepient = state.docuSignEnvelope?.docuSignRecipients.find(
        (r) => r.recipientId === action.payload.recipientId
      );

      if (reciepient) {
        reciepient.name = action.payload.name;
      }
    },
    setRecipientEmail: (state, action) => {
      const reciepient = state.docuSignEnvelope?.docuSignRecipients.find(
        (r) => r.recipientId === action.payload.recipientId
      );

      if (reciepient) {
        reciepient.email = action.payload.email;
      }
    },
    setRecipientCallingCode: (state, action) => {
      const reciepient = state.docuSignEnvelope?.docuSignRecipients.find(
        (r) => r.recipientId === action.payload.recipientId
      );

      if (reciepient) {
        reciepient.phoneNumberCallingCode = action.payload.phoneNumberCallingCode;
      }
    },
    setRecipientPhonenumber: (state, action) => {
      const reciepient = state.docuSignEnvelope?.docuSignRecipients.find(
        (r) => r.recipientId === action.payload.recipientId
      );

      if (reciepient) {
        reciepient.phoneNumber = action.payload.phoneNumber;
      }
    },
  },
});

export const {
  setDocuSignEnvelope,
  setRecipientName,
  setRecipientEmail,
  setRecipientCallingCode,
  setRecipientPhonenumber,
} = SigningSlice.actions;

export default SigningSlice.reducer;
