import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import MDDropDown from "../MDDropDown/MDDropDown";

interface PropType {
  control: any;
  setValue: any;
  formState: any;
  trigger: any;
  defaultCountry: string;
  countryLabel: string;
  countryName: string;
  countryError: any;
  countryValue: string | undefined;
  countryOnChange: (value: any) => void;
}

const MDCountryDropDown = (props: PropType): ReactElement<PropType> => {
  const [t] = useTranslation("general");
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => {
    return state.fileCard;
  });

  const { countryName, countryValue, formState, setValue, trigger } = props;

  useEffect(() => {
    setValue(countryName, countryValue);
  }, [countryName, countryValue, setValue]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(countryName);
    }
  }, [countryName, formState.isSubmitted, trigger]);

  return (
    <MDDropDown
      name={countryName}
      error={props.countryError}
      control={props.control}
      label={props.countryLabel}
      onChangeSetValue={props.countryOnChange}
      value={countryValue === "" ? props.defaultCountry : props.countryValue}
      items={fileCard.allCountries.map((country) => {
        return {
          code: country.isoAlpha3,
          value: country.name,
        };
      })}
      validationText={t("validation.countryRequired")}
    />
  );
};

export default MDCountryDropDown;
