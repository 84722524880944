import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDSwitchButton from "../../UI/MDSwitchButton/MDSwitchButton";
import { removeTimeFromDate } from "../../utils/DateUtils";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { ShipToType } from "../../_types_/ShipToType";
import MDDate from "../../UI/MDDate/MDDate";
import { setShipToDeliveryDate } from "../../_reducers_/FileCardSlice";

interface PropType {
  errors: any;
  control: any;
  shipToId: any;
}

const DeliveryDate = (props: PropType): ReactElement => {
  const dispatch = useDispatch();

  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);

  const [showDeliveryDate, setShowDeliveryDate] = useState(false);

  const { t } = useTranslation("general");
  const { errors, control, shipToId } = props;

  const translDeliveryDateSwitch = t("deliveryAddress.deliveryDateSwitch");
  const translDeliveryDate = t("deliveryAddress.deliveryDate");

  const deliveryAddress = fileCard.fileCard.shipTos.find((o: ShipToType) => o.shipToId === shipToId)?.deliveryAddress;

  const validateDate = (calendarDate: string) => {
    const todayWithTime = new Date();
    const todayNoTime = removeTimeFromDate(todayWithTime);
    const calendarDateNoTime = removeTimeFromDate(new Date(calendarDate));

    if (calendarDateNoTime?.getTime() !== undefined && todayNoTime?.getTime() !== undefined) {
      if (calendarDateNoTime?.getTime() >= todayNoTime?.getTime()) {
        return true;
      } else {
        return t("deliveryAddress.validation.earliestDeliveryDate");
      }
    } else {
      return t("deliveryAddress.validation.earliestDeliveryDate");
    }
  };

  const handleDateChange = (date: Date | null) => {
    dispatch(setShipToDeliveryDate({ shipToId: shipToId, deliveryDate: date?.toISOString() }));
  };

  useEffect(() => {
    if (deliveryAddress?.deliveryDate !== null) {
      setShowDeliveryDate(true);
    }
  }, [deliveryAddress?.deliveryDate]);
  return (
    <>
      <MDSwitchButton
        control={control}
        label={translDeliveryDateSwitch}
        name="showDeliveryDate"
        error={errors.showDeliveryDate}
        value={showDeliveryDate}
        onChangeSetValue={(checked: boolean) => {
          setShowDeliveryDate(checked);
        }}
      />
      {showDeliveryDate ? (
        <MDDate
          control={control}
          disabled={false}
          dateLabel={translDeliveryDate}
          dateName={`shipTo[${shipToId}].deliveryDate`}
          dateError={errors.shipTo ? errors.shipTo[shipToId]?.deliveryDate : undefined}
          dateFormat="dd-MM-yyyy"
          dateValue={
            deliveryAddress?.deliveryDate === null || deliveryAddress?.deliveryDate === undefined
              ? null
              : deliveryAddress?.deliveryDate
          }
          dateOnChange={(e: string): void => {
            handleDateChange(new Date(e));
          }}
          rules={{
            required: {
              message: t("validation.requiredField"),
              value: true,
            },
            validate: validateDate,
          }}
        />
      ) : undefined}
    </>
  );
};

export default DeliveryDate;
